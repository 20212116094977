import { saveAs } from 'file-saver';
import store from './store';

const storeDataToJSON = () => {
  const state = store.getState();
  //Exclude the navigation section and have a clean json file // 
  const stateCopy = { ...state };
  console.log(stateCopy)
  delete stateCopy.navigation;
/********************************************************** */

  const jsonData = JSON.stringify(stateCopy, null, 2);
  return jsonData;
};

const saveJSONToFile = () => {
  const jsonData = storeDataToJSON();
  const blob = new Blob([jsonData], { type: 'application/json' });
  saveAs(blob, 'userInfo.json');
};

export { saveJSONToFile };
