import React from 'react';
import { FaRegUser } from "react-icons/fa6";
import { AiOutlineFileAdd } from "react-icons/ai";
import { LuFileEdit } from "react-icons/lu";
import { TbTargetArrow } from "react-icons/tb";
import Header from './Header.jsx';

import { Link } from 'react-router-dom';

const GuestMode = () => {
  return (
    <div className='bg-gray-100 h-[110vh] '>

     {/**Header */}
     <Header/>
      {/* <div className='h-[150px] w-full bg-[#2A9DEA]'>
        <div className='flex flex-r'>
        <div className='flex flex-r'><img className='pt-2 ml-8' src='/logo.png' width="110" height="auto"/>
        <div className='text-white text-2xl text-left ml-4 pt-10'>UniResume</div>
        </div>
          <div className="relative flex items-center justify-center ml-auto mr-12 w-14 h-14 mt-10 bg-white rounded-full ">
          <FaRegUser className='absolute inset-0 mx-auto my-auto' style={{ fontSize: '20px',color:'#2A9DEA' }} />
          </div>
        </div>
      </div> */}
{/****** **********/}

      <div className='bg-white w-[1000px] h-[600px] ml-[250px] mt-[-40px] hover:border-[#2A9DEA] border-4 mb-14'>
          <h1 className='text-2xl font-normal pt-8'>Welcome, Guest!</h1>
          <p className='text-justify p-12'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus optio perferendis mab fugiat repellat quasi quisquam eligendi sint inventore reiciendis?</p>
          

        <div className='grid grid-cols-2'>
            {/*New Resume */}
          <div className='bg-white ml-12 flex flex-r gap-4 justify-center pt-8 w-96 h-24 mt-12 rounded-xl border-[#2A9DEA] border-2 border-opacity-50 hover:border-opacity-100'>
          <AiOutlineFileAdd style={{color:"#2A9DEA", fontSize: '30px'}}/>
            
              <Link to='/ResumeOptions' className=' text-base'>
               New Resume
               </Link>
          </div>
         
         {/*Edit Resume */}
        <Link  to='/Edit' className='bg-white ml-12 flex flex-r gap-4 justify-center pt-8 w-96 h-24 mt-12 rounded-xl border-[#2A9DEA] border-2 border-opacity-50 hover:border-opacity-100'>
          <LuFileEdit   style={{color:"#2A9DEA", fontSize: '30px'}}/>
            <h1 className=' text-base'> Edit my Resume</h1>
          </Link>

         {/*Dedicated Resume */}
         <div className='bg-white ml-12 flex flex-r gap-4 justify-center pt-8 w-96 h-24 mt-12 rounded-xl border-[#2A9DEA] border-2 border-opacity-50 hover:border-opacity-100'>
          <TbTargetArrow    style={{color:"#2A9DEA", fontSize: '30px'}}/>
            <h1 className=' text-base'> Create a dedicated Resume</h1>
          </div>

        {/*Start from my profile */}
        <div className='bg-gray-200 ml-12 flex flex-r gap-4 justify-center pt-8 w-96 h-24 mt-12 rounded-xl border-gray-100 border-2 '>
          <FaRegUser   style={{color:"gray", fontSize: '26px'}}/>
            <h1 className=' text-base text-gray-500'> Start From my Profile</h1>
          </div>


          </div>
        </div>
    </div>
  );
};

export default GuestMode;
