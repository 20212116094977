import React, { useState } from 'react';
import Header from './Header';
import Container from 'react-bootstrap/Container';
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoArrowBackSharp } from "react-icons/io5";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import { GoEye } from "react-icons/go";
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom';






export default function Profile() {

   const [name, setName] = useState("");
   const [lastName, setLastName] = useState("");
   const [email, setEmail] = useState("");
   const [phone, setPhone] = useState("");

   const [password, setPassword] = useState('');
   const [confirmPass, setConfirmPass] = useState('');
   const [showPassword, setShowPassword] = useState(false);
   const [showConfirmPass, setShowConfirmPass] = useState(false);

   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
   };

   const toggleConfirmPassVisibility = () => {
      setShowConfirmPass(!showConfirmPass);
   };

   //cancel button functionality

   const cancelAction = () => {
      window.history.back(); // Navigate back using the browser's history
    };

   //back button functionality
  
   const goBack = () => {
      window.history.back(); // Go back using the browser's history
    };
  
   return (



      <div >
         <Header></Header>
         <div className='bg-gray-300'>

            {/* <div className='bg-white w-[1000px] h-[600px] ml-[250px] mt-[-40px] border-4 mb-14'> */}
            <div className="flex justify-center items-start h-screen ">

               <div className=" basis-3/4 h-5/6 bg-white z-40 rounded-md shadow-md relative top-0 right-0 mt-[-40px]  p-4 shadow-lg ">


                  <div className='flex flex-row  items-center' onClick={goBack}>
                     <IoArrowBackSharp  />
                     <p className='ml-1 w-9'>  Back</p>
                  </div>

                  <div>
                     <h1 className='text-2xl font-normal pt-6'>Profile</h1>
                  </div>


                  <Container className=' flex pt-16'>
                     <div className='row'>
                        <div className='col pl-20 flex items-center font-bold  '>First Name
                           <IoIosInformationCircleOutline className='text-gray-400 ml-2' />
                        </div>

                        <div className='flex items-center'>
                           <input className='col pt-4 pl-24 text-gray-700 ' type="text" value={name} placeholder='Example' style={{ outline: 'none' }} />
                           <MdOutlineModeEditOutline className='ml-28 hover:text-blue-600' onClick={(e) => setName(e.target.value)} />
                        </div>
                     </div>



                     <div className='row px-10 ml-16'>
                        <div className='col pl-20 flex items-center font-bold  '>Last Name
                           <IoIosInformationCircleOutline className='text-gray-400 ml-2' />
                        </div>
                        <div className='flex items-center'>
                           <input className='col pt-4 pl-24 text-gray-700 ' type="text" value={lastName} placeholder='Example' style={{ outline: 'none' }} />
                           <MdOutlineModeEditOutline className='ml-24 hover:text-blue-600' onClick={(l) => setLastName(l.target.value)} />
                        </div>
                     </div>
                  </Container>

                  <Container className=' flex pt-12'>
                     <div className='row'>
                        <div className='col pl-20 flex items-center font-bold  '>Email
                           <IoIosInformationCircleOutline className='text-gray-400 ml-2' />
                        </div>
                        <div className='flex items-center'>
                           <input className='col pt-4 pl-24 text-gray-700 ' type="text" value={email} placeholder='example@example.com' style={{ outline: 'none' }} />
                           <MdOutlineModeEditOutline className='ml-28 hover:text-blue-600' onClick={(e) => setEmail(e.target.value)} />
                        </div>
                     </div>

                     <div className='row px-10 ml-16'>
                        <div className='col pl-20  flex items-center font-bold  '>Phone Number
                           <IoIosInformationCircleOutline className='text-gray-400 ml-2' />
                        </div>
                        <div className='flex items-center'>
                           <input className='col pt-4 pl-24 text-gray-700 ' type="text" value={phone} placeholder='(+216) 55 555 555' style={{ outline: 'none' }} />
                           <MdOutlineModeEditOutline className='ml-24 hover:text-blue-600' onClick={(e) => setPhone(e.target.value)} />
                        </div>
                     </div>
                  </Container>

                  <Container className='flex pt-12'>
                     <div className='row'>
                        <div className='col pl-20 flex items-center font-bold'>Change Password
                           <IoIosInformationCircleOutline className='text-gray-400 ml-2' />
                        </div>
                        <div className='appearance-none  px-10 mt-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-10'>
                           <TextField className="w-80  border border-gray-200" id="current-password"
                              type={showPassword ? 'text' : 'password'}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="placeholder" 

                              inputProps={{ style: { height: '1.125vh' } }}
                              InputProps={{
                              endAdornment: (

                           <div className='absolute inset-y-0 right-2  flex items-center'>
                           
                              {showPassword ? (
                                 <FaRegEyeSlash className=' text-gray-400 ml-24 ' onClick={togglePasswordVisibility} />
                              ) : (
                                 <GoEye className=' text-gray-400 ml-24 ' onClick={togglePasswordVisibility} />
                              )}
                           </div>
                            ), }}
                           />
                        </div>
                     </div>

                     <div className='row'>
                        <div className='col pl-32 flex items-center font-bold'>Confirm New Password
                           <IoIosInformationCircleOutline className='text-gray-400 ml-2' />
                        </div>
                        <div className=' px-32 mt-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-20"'>
                           <TextField className="w-80  border border-gray-200" id="current-password"
                              type={showConfirmPass ? 'text' : 'password'}
                              value={confirmPass}
                              onChange={(e) => setConfirmPass(e.target.value)}
                              placeholder="placeholder" 
                              inputProps={{ style: { height: '1.125vh' } }}
                              InputProps={{
                              endAdornment: (
                           <div className='absolute inset-y-0 right-2  flex items-center'>
                              {showConfirmPass ? (
                                 <FaRegEyeSlash className='text-gray-400' onClick={toggleConfirmPassVisibility} />
                              ) : (
                                 <GoEye className=' text-gray-400' onClick={toggleConfirmPassVisibility} />
                              )}
                                 
                           </div>
                              ), }}
                             />
                           
                        </div>
                     </div>
                  </Container>


                  <div className='row mt-24  '>
                     <button class="col border border-gray-500 text-sm text-gray-500  py-2 px-16 ml-96 rounded hover:animate-pulse animate duration-1000" onClick={cancelAction} >
                        Cancel
                     </button>
                     <button class="col bg-[#2A9DEA] hover:bg-blue-700 text-sm text-white  py-2 px-10 ml-4 rounded">
                        Save Changes
                     </button>


                  </div>

               </div>
            </div>

         </div>
      </div>


   )
}
