import React, { useState, useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export default function Downloading() {
    const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1000);
    return () => {
        clearTimeout(timeoutId);
      };
    }, [countdown]);
  return (
    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="md">
      <Box sx={{ bgcolor: 'white', height: '60vh' }} className='border border-black rounded-lg' >
          <h1 className='font-bold flex justify-between items-center '>
              <span className="ml-80 text-lg">Download  </span>
          <IconButton className='ml-auto'>
          <ClearIcon/>
            </IconButton>
          </h1>
          <div className='text-left pt-8 px-8 font-bold'>
      {countdown > 0 ? (
        `Your download will begin in ${countdown}...`
      ) : (
        "Starting download..."
      )}
    </div>
          <div className='pt-8 text-left pl-8 t '>If your document doesn't automatically download, please click on "Download Now"</div>





<div className='pt-32'>
<button class="col bg-[#2A9DEA] hover:bg-blue-700 text-sm text-white  py-2 px-16 ml-4 rounded">
Download Now  
</button>
</div>

      </Box>
    </Container>
  </React.Fragment>











  )
}
