import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    navigateToLanguages : false,
    navigateToSkills : false,
    navigateToEducationSection: false,
    navigateToCertifications: false,

    navigateToWorkExperience : false,
    navigateToWorkTwo : false,
    navigateToWorkThree : false,
    navigateToWorkFour : false,

    navigateToSummary: false,
    navigateToPersonalInfo: false,
    navigateToEducationTwo: false,
    navigateToEducationThree: false, 

    navigateToCertificateTwo : false,
    navigateToCertificateThree : false,
    navigateToCertificateFour : false,
    navigateToCertificateFive : false,
    navigateToCertificateSix : false,
  },


  reducers: {
 /*****************************Personal Infos Navigation ********************* */   
    
    navigateToPersonalInfo:(state) =>{
      state.navigateToPersonalInfo = true;

      state.navigateToSummary= false;
      state.navigateToLanguages = false;
      state.navigateToSkills = false;
      state.navigateToCertifications= false;

      state.navigateToWorkExperience = false;
      state.navigateToWorkTwo = false;
      state.navigateToWorkThree = false;
      state.navigateToWorkFour = false;

      state.navigateToEducationSection= false;
      state.navigateToEducationTwo = false;
      state.navigateToEducationThree = false;

      state.navigateToCertificateTwo= false;
      state.navigateToCertificateThree= false;
      state.navigateToCertificateFour= false;
      state.navigateToCertificateFive= false;
      state.navigateToCertificateSix= false;

    },
 /*****************************Languages Navigation ********************* */   

    navigateToLanguages: (state) => {
      state.navigateToLanguages = true;
  
      state.navigateToEducationSection= false;
      state.navigateToEducationTwo = false;
      state.navigateToEducationThree = false;

      state.navigateToWorkExperience = false;
      state.navigateToWorkTwo = false;
      state.navigateToWorkThree = false;
      state.navigateToWorkFour = false;

      state.navigateToSummary= false;
      state.navigateToPersonalInfo = false;
      state.navigateToCertifications= false;
      state.navigateToSkills = false;

      state.navigateToCertificateTwo= false;
      state.navigateToCertificateThree= false;
      state.navigateToCertificateFour= false;
      state.navigateToCertificateFive= false;
      state.navigateToCertificateSix= false;

    },
 /*****************************Skills Navigation ********************* */   

     navigateToSkills : (state) => {
      state.navigateToSkills  = true;

      state.navigateToSummary= false;
      state.navigateToPersonalInfo = false;
      state.navigateToLanguages = false;
      state.navigateToCertifications= false;

      state.navigateToEducationSection= false;
      state.navigateToEducationTwo = false;
      state.navigateToEducationThree = false;


      state.navigateToWorkExperience = false;
      state.navigateToWorkTwo = false;
      state.navigateToWorkThree = false;
      state.navigateToWorkFour = false;

      state.navigateToCertificateTwo= false;
      state.navigateToCertificateThree= false;
      state.navigateToCertificateFour= false;
      state.navigateToCertificateFive= false;
      state.navigateToCertificateSix= false;

    },
 /*****************************Educations Navigation ********************* */   

    navigateToEducationSection : (state) => {
    state.navigateToEducationSection= true;
    state.navigateToEducationTwo = false;
    state.navigateToEducationThree = false;
    
      state.navigateToLanguages = false;
      state.navigateToSkills = false;
      state.navigateToCertifications= false;
      state.navigateToSummary= false;
      state.navigateToPersonalInfo = false;

      state.navigateToWorkExperience = false;
      state.navigateToWorkTwo = false;
      state.navigateToWorkThree = false;
      state.navigateToWorkFour = false;

      state.navigateToCertificateTwo= false;
      state.navigateToCertificateThree= false;
      state.navigateToCertificateFour= false;
      state.navigateToCertificateFive= false;
      state.navigateToCertificateSix= false;
    },

    navigateToEducationTwo : (state) => {
      state.navigateToEducationTwo= true;
      state.navigateToEducationSection = false;
      state.navigateToEducationThree = false;
      
        state.navigateToLanguages = false;
        state.navigateToSkills = false;
        state.navigateToCertifications= false;
        state.navigateToSummary= false;
        state.navigateToPersonalInfo = false;

        state.navigateToWorkExperience = false;
        state.navigateToWorkTwo = false;
        state.navigateToWorkThree = false;
        state.navigateToWorkFour = false;

        state.navigateToCertificateTwo= false;
        state.navigateToCertificateThree= false;
        state.navigateToCertificateFour= false;
        state.navigateToCertificateFive= false;
        state.navigateToCertificateSix= false;


      },
      navigateToEducationThree : (state) => {
        state.navigateToEducationThree= true;

        state.navigateToEducationSection = false;
         state.navigateToEducationTwo= false;

          state.navigateToLanguages = false;
          state.navigateToSkills = false;
          state.navigateToCertifications= false;  
          state.navigateToSummary= false;
          state.navigateToPersonalInfo = false;

          state.navigateToWorkExperience = false;
          state.navigateToWorkTwo = false;
          state.navigateToWorkThree = false;
          state.navigateToWorkFour = false;

          state.navigateToCertificateTwo= false;
          state.navigateToCertificateThree= false;
          state.navigateToCertificateFour= false;
          state.navigateToCertificateFive= false;
          state.navigateToCertificateSix= false;
        },
 /***************************** Certificates Navigation ********************* */   
    navigateToCertifications : (state) => {
        state.navigateToCertifications= true;
        
          state.navigateToLanguages = false;
          state.navigateToSkills = false;
          state.navigateToSummary= false;
          state.navigateToPersonalInfo = false;


          state.navigateToEducationSection= false;
          state.navigateToEducationTwo = false;
          state.navigateToEducationThree = false;

          state.navigateToWorkExperience = false;
          state.navigateToWorkTwo = false;
          state.navigateToWorkThree = false;
          state.navigateToWorkFour = false;

          state.navigateToCertificateTwo= false;
          state.navigateToCertificateThree= false;
          state.navigateToCertificateFour= false;
          state.navigateToCertificateFive= false;
          state.navigateToCertificateSix= false;

        },


        navigateToCertificateTwo : (state) => {
          state.navigateToCertificateTwo= true;
          
          state.navigateToCertifications= false;
          state.navigateToCertificateThree= false;
          state.navigateToCertificateFour= false;
          state.navigateToCertificateFive= false;
          state.navigateToCertificateSix= false;
          
            state.navigateToLanguages = false;
            state.navigateToSkills = false;
            state.navigateToSummary= false;
            state.navigateToPersonalInfo = false;
  
  
            state.navigateToEducationSection= false;
            state.navigateToEducationTwo = false;
            state.navigateToEducationThree = false;
  
            state.navigateToWorkExperience = false;
            state.navigateToWorkTwo = false;
            state.navigateToWorkThree = false;
            state.navigateToWorkFour = false;
  
          },
  
          navigateToCertificateThree : (state) => {
            state.navigateToCertificateThree=  true;
          
            state.navigateToCertifications= false;
            state.navigateToCertificateTwo= false;
            state.navigateToCertificateFour= false;
            state.navigateToCertificateFive= false;
            state.navigateToCertificateSix= false;
            
              state.navigateToLanguages = false;
              state.navigateToSkills = false;
              state.navigateToSummary= false;
              state.navigateToPersonalInfo = false;
    
    
              state.navigateToEducationSection= false;
              state.navigateToEducationTwo = false;
              state.navigateToEducationThree = false;
    
              state.navigateToWorkExperience = false;
              state.navigateToWorkTwo = false;
              state.navigateToWorkThree = false;
              state.navigateToWorkFour = false;
    
            },

            navigateToCertificateFour : (state) => {
              state.navigateToCertificateFour= true;

              state.navigateToCertifications= false;
              state.navigateToCertificateTwo= false;
              state.navigateToCertificateThree= false;
              state.navigateToCertificateFive= false;
              state.navigateToCertificateSix= false;
              
                state.navigateToLanguages = false;
                state.navigateToSkills = false;
                state.navigateToSummary= false;
                state.navigateToPersonalInfo = false;
      
      
                state.navigateToEducationSection= false;
                state.navigateToEducationTwo = false;
                state.navigateToEducationThree = false;
      
                state.navigateToWorkExperience = false;
                state.navigateToWorkTwo = false;
                state.navigateToWorkThree = false;
                state.navigateToWorkFour = false;
      
              },
              navigateToCertificateFive : (state) => {
                state.navigateToCertificateFive= true;

                state.navigateToCertifications= false;
                state.navigateToCertificateTwo= false;
                state.navigateToCertificateThree= false;
                state.navigateToCertificateFour= false;
                state.navigateToCertificateSix= false;
                
                  state.navigateToLanguages = false;
                  state.navigateToSkills = false;
                  state.navigateToSummary= false;
                  state.navigateToPersonalInfo = false;
        
        
                  state.navigateToEducationSection= false;
                  state.navigateToEducationTwo = false;
                  state.navigateToEducationThree = false;
        
                  state.navigateToWorkExperience = false;
                  state.navigateToWorkTwo = false;
                  state.navigateToWorkThree = false;
                  state.navigateToWorkFour = false;
        
                },
                navigateToCertificateSix : (state) => {
                  state.navigateToCertificateSix= true;

                  state.navigateToCertifications= false;
                  state.navigateToCertificateTwo= false;
                  state.navigateToCertificateThree = false;
                  state.navigateToCertificateFour= false;
                  state.navigateToCertificateFive= false;
      
                  
                    state.navigateToLanguages = false;
                    state.navigateToSkills = false;
                    state.navigateToSummary= false;
                    state.navigateToPersonalInfo = false;
          
          
                    state.navigateToEducationSection= false;
                    state.navigateToEducationTwo = false;
                    state.navigateToEducationThree = false;
          
                    state.navigateToWorkExperience = false;
                    state.navigateToWorkTwo = false;
                    state.navigateToWorkThree = false;
                    state.navigateToWorkFour = false;
          
                  },
 /*****************************Work Experiences Navigation ********************* */       
  navigateToWorkExperience : (state) => {
          state.navigateToWorkExperience= true;


          state.navigateToWorkTwo= false;
          state.navigateToWorkThree= false;
          state.navigateToWorkFour= false;
            
          state.navigateToLanguages = false;
          state.navigateToSkills = false;
          state.navigateToCertifications = false;
          state.navigateToSummary= false;
          state.navigateToPersonalInfo = false;

          state.navigateToEducationSection= false;
          state.navigateToEducationTwo = false;
          state.navigateToEducationThree = false;

          state.navigateToCertificateTwo= false;
          state.navigateToCertificateThree = false;
          state.navigateToCertificateFour= false;
          state.navigateToCertificateFive= false;
          state.navigateToCertificateSix= false;
            },
            
  navigateToWorkTwo : (state) => {
            state.navigateToWorkTwo= true;

            state.navigateToWorkExperience= false;
            state.navigateToWorkThree= false;
            state.navigateToWorkFour= false;


            state.navigateToLanguages = false;
            state.navigateToSkills = false;
            state.navigateToCertifications = false;
            state.navigateToSummary= false;
            state.navigateToPersonalInfo = false;
  
            state.navigateToEducationSection= false;
            state.navigateToEducationTwo = false;
            state.navigateToEducationThree = false;

            state.navigateToCertificateTwo= false;
            state.navigateToCertificateThree = false;
            state.navigateToCertificateFour= false;
            state.navigateToCertificateFive= false;
            state.navigateToCertificateSix= false;
              },
  
navigateToWorkThree : (state) => {
  state.navigateToWorkThree= true;

                state.navigateToWorkTwo= false;
                state.navigateToWorkExperience= false;
                state.navigateToWorkFour= false;
    
                state.navigateToLanguages = false;
                state.navigateToSkills = false;
                state.navigateToCertifications = false;
                state.navigateToSummary= false;
                state.navigateToPersonalInfo = false;
      
                state.navigateToEducationSection= false;
                state.navigateToEducationTwo = false;
                state.navigateToEducationThree = false;

                state.navigateToCertificateTwo= false;
                state.navigateToCertificateThree= false;
                state.navigateToCertificateFour= false;
                state.navigateToCertificateFive= false;
                state.navigateToCertificateSix= false;
                  },

navigateToWorkFour : (state) => {
  state.navigateToWorkFour= true;
                  
       state.navigateToWorkTwo= false;
     state.navigateToWorkExperience= false;
      state.navigateToWorkThree= false;
                      
       state.navigateToLanguages = false;
       state.navigateToSkills = false;
       state.navigateToCertifications = false;
       state.navigateToSummary= false;
       state.navigateToPersonalInfo = false;
                        
      state.navigateToEducationSection= false;
      state.navigateToEducationTwo = false;
      state.navigateToEducationThree = false;

      state.navigateToCertificateTwo= false;
      state.navigateToCertificateThree= false;
      state.navigateToCertificateFour= false;
      state.navigateToCertificateFive= false;
      state.navigateToCertificateSix= false;
 },
 /*****************************Summary Navigation ********************* */   
    navigateToSummary : (state) => {
                state.navigateToSummary= true;
    
                state.navigateToLanguages = false;
                state.navigateToSkills = false;
                state.navigateToCertifications= false;
                state.navigateToPersonalInfo = false;

                state.navigateToEducationSection= false;
                state.navigateToEducationTwo = false;
                state.navigateToEducationThree = false;

                state.navigateToWorkExperience = false;
                state.navigateToWorkTwo = false;
                state.navigateToWorkThree = false;
                state.navigateToWorkFour = false;

                state.navigateToCertificateTwo= false;
                state.navigateToCertificateThree= false;
                state.navigateToCertificateFour= false;
                state.navigateToCertificateFive= false;
                state.navigateToCertificateSix= false;

                },

    resetNavigation: (state) => {

      state.navigateToLanguages = false;
      state.navigateToSkills = false;
      state.navigateToSummary= false;
      state.navigateToPersonalInfo = false;

      state.navigateToEducationSection= false;
      state.navigateToEducationTwo = false;
      state.navigateToEducationThree = false;

      state.navigateToWorkExperience = false;
      state.navigateToWorkTwo = false;
      state.navigateToWorkThree = false;
      state.navigateToWorkFour = false;

      state.navigateToCertifications= false;
      state.navigateToCertificateTwo= false;
      state.navigateToCertificateThree= false;
      state.navigateToCertificateFour= false;
      state.navigateToCertificateFive= false;
      state.navigateToCertificateSix= false;
    },
  },
});



export const {
  navigateToLanguages,
  navigateToSkills,
  navigateToSummary,
  navigateToPersonalInfo,

  navigateToEducationSection,
  navigateToEducationTwo,
  navigateToEducationThree,


  navigateToWorkExperience,
  navigateToWorkTwo,
  navigateToWorkThree,
  navigateToWorkFour,


  navigateToCertifications,
  navigateToCertificateTwo,
  navigateToCertificateThree,
  navigateToCertificateFour,
  navigateToCertificateFive,
  navigateToCertificateSix,

  resetNavigation,

} = navigationSlice.actions;

export const selectNavigationState = (state) => state.navigation;

export default navigationSlice.reducer;
