import { createSlice } from '@reduxjs/toolkit';

export const workSlice = createSlice({
  name: 'work',
  initialState: {
    workList: [],
  },
  reducers: {
    addWork: (state, action) => {
        console.log('Payload received:', action.payload);
        state.workList = [...state.workList, action.payload];
        console.log('Updated workList:', state.workList);  
    },
  },
});


export const { addWork } = workSlice.actions;

export const selectWorkState = (state) => state.work;

export default workSlice.reducer;
