import { configureStore } from '@reduxjs/toolkit';

import summaryReducer from './summarySlice';
import navigationReducer from './navigationSlice';
import skillsReducer from './skillSlice';
import personalInfoReducer from './personalInfoSlice';
import educationReducer from './EducationSlice';
import workReducer from './workSlice';
import certificateReducer from './CertificateSlice';
import LanguageReducer from './LanguagesSlice';


const store = configureStore({
  reducer:{
    summary : summaryReducer,
    navigation : navigationReducer,
    skills: skillsReducer, 
    personalInfo: personalInfoReducer,
    education : educationReducer,
    work : workReducer,
    certificate : certificateReducer,
    language : LanguageReducer,

  }
});


export default store;