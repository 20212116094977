import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import React from 'react'

const Steppers = ({activeStep}) => {

  const steps = ['','','','','','','']
    


  return (
    <div>
      <Box sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((_, index) => (
                  <Step key={index}>
                    <StepLabel>{}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
    </div>
  )
}

export default Steppers;
