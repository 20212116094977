import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';

export default function Download() {
  return (

    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Box sx={{ bgcolor: 'white', height: '60vh' }} className='border border-black rounded-lg' >
            <h1 className='font-bold flex justify-between items-center '>
                <span className="ml-80 text-lg">Download my Resume  </span>
            <IconButton className='ml-auto'>
            <ClearIcon/>
              </IconButton>
            </h1>
            <div className='text-left pt-8 px-8'>Your resume is ready to be downloaded at: </div>

            <div className='pt-8 text-center pl-8 text-[#0BA36C] font-bold'>10TND</div>
            <div className='pt-1 text-center pl-8 text-[#0BA36C] font-bold'>6 Months Validity</div>



            <Box
      sx={{
        width: 900,
        maxWidth: '100%',
      }}
      className='pt-6 px-8 flex flex-row items-center '
    >
        <span className='mr-4'>Coupon code: </span>
      <TextField  placeholder="Coupon code placeholder" style={{ width:'80%' }} id="fullWidth"
      InputProps={{
        endAdornment: (
          <IconButton>
            <ClearIcon />
          </IconButton>
        ),
      }} />
    </Box>


<div className='pt-12'>
<button class="col border border-gray-500 text-sm text-gray-500  py-2 px-16 ml-96 rounded hover:animate-pulse animate duration-1000">
  Cancel
</button>
<button class="col bg-[#2A9DEA] hover:bg-blue-700 text-sm text-white  py-2 px-16 ml-4 rounded">
  Pay 
</button>
</div>

        </Box>
      </Container>
    </React.Fragment>
  )
}
