import React from 'react'
import Header from './Header'
import { IoArrowBackSharp } from "react-icons/io5";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Hiring from '../images/Hiring.png';
import Handshake from '../images/Handshake.png';
import Europe from '../images/Europe.png';
import Canada from '../images/Canada.png';


const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );
  
  const goBack = () => {
    window.history.back(); // Go back using the browser's history
  };

export default function ResumeOptions() {
  return (
    <div>
        <Header></Header>
        <div className="flex justify-center items-start h-screen ">
     <div className=" basis-3/4 h-5/6 bg-white z-40 rounded-md shadow-md relative top-0 right-0 mt-[-40px]  p-4 shadow-lg z-10">
         <div className='flex flex-row  items-center'  onClick={goBack}>
        <IoArrowBackSharp /> 
         <p className='ml-1 w-9'>  Back</p>
        </div> 
     <h1 className=' w-30 ml-4 mt-2 mb-10 text-2xl'>New Resume</h1> 
     
       <div>
        <p className='text-left mt-14 text-sm font-bold'>What are you looking for ? </p>
       <p className='mb-7 mt-2 text-left text-sm'>Crafting the perfect resume begins with choosing the right format. Your resume is your professional snapshot, and the style you choose should reflect your unique career journey. Below, we've outlined different resume types tailored to various career paths. Take a moment to consider your experience,goals,and the industry you're in. Ready to get started? Pick a card below that resonates with your career story,and let's create a standout resume together !</p>
   
 
   <div className='flex flex-row gap-12 mt-20 '>

   <Card  
   className='w-52 h-72 border rounded-lg border-[#2A9DEA]' >
   <div class="flex items-center justify-center mt-4 h-50 ">
  <img src={Hiring} alt="Description of the image" class="w-40 ml-4 mr-4" />
  </div>
      <CardContent className='mt-10 '>
        <Typography variant="body1 text-xs text-black ">
        An internship/ entry-level job        
        </Typography>
      </CardContent>
    </Card>


   <Card  className='w-52 h-72 border rounded-lg border-[#2A9DEA] '>
   <div class="flex items-center justify-center mt-4 h-50">
  <img src={Handshake} alt="Description of the image" class="w-40 ml-4 mr-4" />
  </div>
      <CardContent className='mt-10'>
        <Typography variant="body1 text-xs text-black ">
        A new position       
        </Typography>
      </CardContent>
    </Card>

    <Card  className='w-52 h-72 border rounded-lg border-[#2A9DEA] '>
   <div class="flex items-center justify-center mt-4 h-50">
  <img src={Europe} alt="Description of the image" class="w-40 ml-4 mr-4" />
  </div>
      <CardContent className='mt-10'>
        <Typography variant="body1 text-xs text-black ">
        A job in Europe       
        </Typography>
      </CardContent>
    </Card>


    <Card  className='w-52 h-72 border rounded-lg border-[#2A9DEA] '>
   <div class="flex items-center justify-center mt-4 h-50">
  <img src={Canada} alt="Description of the image" class="w-40 ml-4 mr-4" />
  </div>
      <CardContent className='mt-10'>
        <Typography variant="body1 text-xs text-black ">
        A job in Canada       
        </Typography>
      </CardContent>
    </Card>



   </div>




      </div>
      </div>
      </div>
    </div>
  )
}
