import React,{useState} from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaBold, FaItalic, FaUnderline, FaStrikethrough } from "react-icons/fa";
import { MdEmojiEmotions } from "react-icons/md";
import {useSelector, useDispatch } from 'react-redux'; 
import { finishSummary,addSum } from '../Redux/summarySlice';
import { navigateToLanguages } from '../Redux/navigationSlice';
import { selectNavigationState } from '../Redux/navigationSlice';
import { selectSummaryState } from '../Redux/summarySlice';
import { saveJSONToFile } from '../Redux/ReduxToJSON'

const Summary = ({handleSquareClick}) => {
  const dispatch = useDispatch(); //Redux Toolkit hook //
  const navigationState = useSelector(selectNavigationState);
  const summaryState = useSelector(selectSummaryState);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)};
  const editorText = editorState.getCurrentContent().getPlainText();



//Store Summary in the redux store //
const handleAddSum = () => {
  if ( editorText.trim() !== '') {
    dispatch(addSum(editorText.trim())); 
  }
};


  /**************************Previous Section Button functionnality ***********/
  const handlePreviousSectionClick = () =>{
    dispatch(navigateToLanguages());
    handleSquareClick(5)
};


  /*****************Finish button functionnality****** */
  const handleFinishSummary = () => {
    handleAddSum();
    saveJSONToFile(); 
    dispatch(finishSummary());

  }; 

  return (
    <div>
     <div><h1 className='text-left mb-2 pl-8 mt-10 text-black'>Summary</h1>
          <p className='text-xs text-justify leading-5 mb-4 pl-8 pr-8 text-gray-500 '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quia sed officia fugiat.consectetur adipiscing elit. Quia sed officia fugiat.</p>

          <div className='ml-6 mr-6 mt-8 bg-gray-100 min-h-72'>
          <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
              bold: <FaBold />,
              italic: <FaItalic />,
              underline: <FaUnderline />,
              strikethrough: <FaStrikethrough />,
              emoji: <MdEmojiEmotions />,
            },

            fontFamily: {
              options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
            },

          }}
    
        />
      </div>
      <div className='flex flex-col md:flex-row ml-6 mr-6 mt-1 text-xs text-gray-500 text-justify leading-6'>
        <span className='font-bold text-left mr-1 '>TIP: </span>
        <p className=' text-left text-gray-500 '>For this kind of Resume, it is recommanded to add a summary in which you talk about : 
        Lorem ipsum dolor sit amet consecteturste sunt repellendus voluptates, illum laboriosam impedit distinctio earum delectus sint harum praesentium eaque rerum qui quos officia, aliquid explicabo deserunt. </p>
      </div>
      <div className='mt-10 gap-12 text-right mr-6'>
      <button className='bg-white px-6 mr-8 z-30 py-2 rounded-md border-2 border-gray-200 relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-gray-200 after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-xs text-gray-500 '
        onClick={() => handlePreviousSectionClick()}>Previous Section</button>
       <button className='bg-[#2a9dea] px-8 z-30 py-2 rounded-md text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#34739d] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 [text-shadow:3px_5px_2px_#2a9dea] hover:[text-shadow:2px_2px_2px_#2a9dea] text-xs'
       onClick={handleFinishSummary}>Next Section</button>
      </div>
    </div>

    </div> 
  )
}

export default Summary;
