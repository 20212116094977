import { createSlice } from '@reduxjs/toolkit';

export const educationSlice = createSlice({
  name: 'education',
  initialState: {
    EducationList: [],
  },
  reducers: {
    addEd: (state, action) => {
        console.log('Payload received:', action.payload);
        state.EducationList = [...state.EducationList, action.payload];
        console.log('Updated EducationList:', state.EducationList);   
    },
  },
  
});

export const { addEd } = educationSlice.actions;

export const selectEducationState = (state) => state.education;

export default educationSlice.reducer;
