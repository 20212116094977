import { createSlice } from '@reduxjs/toolkit';

const summarySlice = createSlice({
  name: 'summary',
  initialState: {
    summaryFinished: false,
    summaryContent : []
    
  },
  reducers: {
    addSum: (state, action) => {
      console.log('Payload received:', action.payload);
      state.summaryContent = [...state.summaryContent, action.payload];
      console.log('Updated summaryCOntent:', state.summaryContent);  
    },
    finishSummary(state) {
      state.summaryFinished = true;
    },
    resetSummary: (state) => {
        state.summaryFinished = false;
      },
  },
});



export const { finishSummary,resetSummary,addSum } = summarySlice.actions;
export const selectSummaryState = (state) => state.summary;
export default summarySlice.reducer;
