import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { IoMdClose } from "react-icons/io";
export default function Problem() {
    
    
  return (
    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="md">
      <Box sx={{ bgcolor: 'white', height: '60vh' }} className='border border-black rounded-lg' >
      <div className='flex justify-end'>
      <IconButton className=''>
          <ClearIcon/>
            </IconButton>
            </div>
        
        
          <div className=' flex flex-col items-center '>
          <IoMdClose className='text-[#EF1313] text-5xl font-bold' />
              <span className="mt-2 text-3xl text-[#EF1313]">Oops..  </span>
          </div>
          <div className='text-left pt-8 px-8 font-bold'>There seems to be a problem in your payment </div>

          <div className='pt-4 text-left pl-8 text-gray-500'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet sem consectetur, aliquam nisi ut.</div>





<div className='pt-32'>
<button class="col border border-gray-500 text-sm text-gray-500  py-2 px-16 ml-12 rounded hover:animate-pulse animate duration-1000" >
Cancel
</button>
<button class="col bg-[#2A9DEA] hover:bg-blue-700 text-sm text-white  py-2 px-12 ml-4 rounded">
Try again 
</button>
</div>

      </Box>
    </Container>
  </React.Fragment>
  )
}
