import { createSlice } from '@reduxjs/toolkit';

export const certificateSlice = createSlice({
  name: 'certificate',
  initialState: {
    CertificateList: [],
  },
  reducers: {
    addCertif: (state, action) => {
        console.log('Payload received:', action.payload);
        state.CertificateList = [...state.CertificateList, action.payload];
        console.log('Updated CertificateList:', state.CertificateList);   
    },
  },
  
});

export const { addCertif } = certificateSlice.actions;

export const selectCertificateState = (state) => state.certificate;

export default certificateSlice.reducer;
