import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export default function SaveGuest() {
 
  
  return (
    <div>

    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="md">
      <Box sx={{ bgcolor: 'white', height: '60vh' }} className='border border-black rounded-lg' >
          <h1 className='font-bold flex justify-between items-center '>
              <span className="ml-80 text-lg">Save my Resume  </span>
          <IconButton className='ml-auto'>
          <ClearIcon/>
            </IconButton>
          </h1>
          <div className='text-left pt-14 px-8'>Please sign up or log in to your account.  </div>


<div className='pt-6 text-left pl-8'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet sem consectetur.</div>

<div className='pt-32  flex items-center justify-center'>
<button class="col border border-gray-500 text-sm text-gray-500 py-2 px-4  mr-4 rounded">
Create an Account
</button>
<span className='pr-4'> or</span>
<button class="col bg-[#2A9DEA] hover:bg-blue-700 text-sm text-white  py-2 px-12 rounded">
Log in 
</button>
</div>

      </Box>
    </Container>
  </React.Fragment>
  </div>
);
}

  