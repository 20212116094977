import React, { useState } from 'react';
import { MdOutlineMailOutline } from "react-icons/md";
import { HiOutlineLockClosed } from "react-icons/hi";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import { NavLink } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);  // state to show and hide the password of user //
  const [showConfirm , setShowConfirm] = useState(false); // state to show and hide the Confirmpassword of user //
  const [checked, setChecked] = useState(false);    // state to handle the checking of Accept terms and conditions //

  const handleChange = (event) => {setChecked(event.target.checked)} 




  return (
    <div className='flex flex-r'>
      <div className='bg-gray-300 h-screen'>
        <img src='/logpic.png' className='w-full h-screen object-cover' alt='Signup'/>
      </div>
      <div className='bg-white w-full md:w-1/2 max-w-full mt-8 md:mt-0 p-8 md:p-4 lg:ml-11'>
        <h1 className='text-5xl font-normal mb-10 '> Sign Up </h1>
        <h2 className='text-2xl font-normal mb-4'>to Resume Generator</h2>
        <h3> Already have an account ?  <NavLink to={'/Login'} ><span className='text-[#2A9DEA]'>Sign in here </span></NavLink></h3>
        
  {/**First name and last name Input */}

  <div className="relative w-[50%] max-w-full mt-10 ml-4 md:ml-48 mr-4 md:mr-24">
    <div className='flex flex-r gap-6 w-full'>
          <input type="text" className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s rounded-lg focus:ring-[#2A9DEA]  focus:bg-white focus:border-[#2A9DEA] block w-1/2 h-12 p-2.5 pl-10" placeholder=  " First Name "/>
          <PersonIcon style={{ fontSize: '1.2rem' }} className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400 mr-3"/>
          <input type="text" className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s rounded-lg focus:ring-[#2A9DEA]  focus:bg-white focus:border-[#2A9DEA] block w-1/2 h-12 p-2.5 pl-4 " placeholder=  " Last Name "/>
        </div>
        </div>

{/**Date of Birth Input */}
<div className="relative w-[50%] max-w-full mt-4 ml-4 md:ml-48 mr-4 md:mr-24">
          <input type="text" className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s rounded-lg focus:ring-[#2A9DEA]  focus:bg-white focus:border-[#2A9DEA] block w-full h-12 p-2.5 pl-10 " placeholder=  " Date of Birth"/>
          <CalendarMonthIcon   style={{ fontSize: '1rem' }} className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400 mr-3"/>
        </div>

  {/**Email Input */}
        <div className="relative w-[50%] max-w-full mt-4 ml-4 md:ml-48 mr-4 md:mr-24">
          <input type="text" className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s rounded-lg focus:ring-[#2A9DEA]  focus:bg-white focus:border-[#2A9DEA] block w-full h-12 p-2.5 pl-10 " placeholder=  " Email"/>
          <MdOutlineMailOutline className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400 mr-3"/>
        </div>

   {/**Password Input */}
        <div className="relative w-[50%] max-w-full mt-4 ml-4 md:ml-48 mr-4 md:mr-24">
          <div className="relative">
            <input type={showPassword ? "text" : "password"} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s rounded-lg focus:ring-[#2A9DEA]  focus:bg-white focus:border-[#2A9DEA] block w-full h-12 p-2.5 pl-11 " placeholder=  "Password"/>
            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"/> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"/>}
          </div>
          <HiOutlineLockClosed className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400 mr-3"/>
        </div>

  {/**Confirm Password Input */}
  <div className="relative w-[50%] max-w-full mt-4 ml-4 md:ml-48 mr-4 md:mr-24">
          <div className="relative">
            <input type={showConfirm ? "text" : "Confirm password"} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s rounded-lg focus:ring-[#2A9DEA]  focus:bg-white focus:border-[#2A9DEA] block w-full h-12 p-2.5 pl-11 " placeholder=  "Confirm Password"/>
            {showConfirm? <FaRegEye onClick={() => setShowConfirm(false)} className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"/> : <FaRegEyeSlash onClick={() => setShowConfirm(true)} className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"/>}
          </div>
          <HiOutlineLockClosed className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400 mr-3"/>
        </div>

{/**Accept terms checkbox */}
     
        <div className='mt-2 ml-4 w-[50%] max-w-full md:ml-32 '>
        <Checkbox style={{color : checked ? "#2A9DEA" : "gray"}}
      checked={checked}
      onChange={handleChange}/><span className='text-gray-800'> I accept Terms and Conditions. </span>
   
      </div>

      <div className='mt-8'>
      <button  className='bg-[#2A9DEA] hover:bg-opacity-80 w-1/4 text-white font-semibold h-12 rounded-xl text-center' ><AddIcon className='mr-2'/>
        Sign Up
      </button>
      </div>
     
     
      </div>
    </div>
  )
}

export default Signup;

