import { createSlice } from '@reduxjs/toolkit';

export const skillsSlice = createSlice({
  name: 'skills',
  initialState: {
    skillsList: [],
  },
  reducers: {
    addSkill: (state, action) => {
      console.log('Payload received:', action.payload);
      state.skillsList = [...state.skillsList, action.payload];
      console.log('Updated skillsList:', state.skillsList);  
    },
    deleteSkill: (state, action) => {
      console.log('Payload received for deletion:', action.payload);
      state.skillsList = state.skillsList.filter(skill => skill !== action.payload);
      console.log('Updated skillsList after deletion:', state.skillsList);
  }}
});


export const { addSkill,deleteSkill } = skillsSlice.actions;

export const selectSkillsState = (state) => state.skills;

export default skillsSlice.reducer;
