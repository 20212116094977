import React from 'react'

const Finish = () => {
  return (
    <div>
      <h1 className='text-3xl'>Congratulations! </h1>
      <p className='text-justify  m-10 mb-1'>You have filled all the required sections of your <span className='font-bold'>Canadian Resume</span></p>
    <p className='text-justify text-gray-600 mt-2 ml-10 mr-10'>Lorem ipsum dolor sit amet consectetur adii, repudiandae ipsam. nt sunt voluptatibus magnam nihil dolore.</p>
    <p className='text-justify text-gray-600 mt-2 ml-10 mr-10'>`There are other type of sections that are allowed in a Canadian Resume like : 
      <ul>
        <li className='ml-12'>ipsum dolor sit amet</li>
        <li  className='ml-12'>ipsum dolor sit amet</li>
        <li  className='ml-12'>ipsum dolor sit amet</li></ul>`  </p>
        <div className='flex flex-r gap-6 ml-12'>
        <button className=' mt-6 w-40 h-10 hover:bg-slate-100 rounded bg-white border-2 border-gray-200 text-gray-700 text-xs '> Edit my Resume</button>
        <button className=' mt-6 w-40 h-10 hover:bg-opacity-60 rounded bg-gray-300 text-gray-700 text-xs'> Save my Resume </button>
        <div>
        <button className=' mt-6 w-40 h-10 hover:bg-opacity-60 rounded bg-[#2A9DEA] text-white text-xs'> Download my Resume </button>
      <h1 className='text-[#2A9DEA] text-xs mt-2'>For only 10TND </h1>
        </div>
        </div>
    </div>
  )
}

export default Finish;