import { createSlice } from '@reduxjs/toolkit';

export const personalInfoSlice = createSlice({
  name: 'personalInfo',
  initialState: {
    InfoList: [],
  },
  reducers: {
    addInfo: (state, action) => {
      console.log('Payload received:', action.payload);
      state.InfoList = [...state.InfoList, action.payload];
      console.log('Updated InfoList:', state.InfoList);  
    },
  },
  
});

export const { addInfo } = personalInfoSlice.actions;

export const selectInfoState = (state) => state.personalInfo;

export default personalInfoSlice.reducer;
