 import React from 'react';
 import { IoArrowBackSharp } from "react-icons/io5";
 import { FaArrowDown } from "react-icons/fa6";
 import { FaArrowUp } from "react-icons/fa6";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

const goBack = () => {
  window.history.back(); // Go back using the browser's history
};

// const Box = () => {
//   return (
//     <div className="flex justify-center items-start h-screen ">
//       <div className=" basis-3/4 h-5/6 bg-white z-40 rounded-md shadow-md relative top-0 right-0 mt-[-40px]  p-4 shadow-lg z-10">
//         <div className='flex flex-row  items-center'>
//         <IoArrowBackSharp /> 
//         <p className='ml-1 w-9'>  Back</p>
//         </div> 
//       <h1 className=' w-30 ml-4 mt-2 mb-7 text-2xl'>Edit my Resume</h1> 
     
//       <div>
//       <p className='mb-7'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt . Ut enim ad minim veniam</p>
//       {/* <img className='mt7' src={require('../images/Table.png')}></img> */}


//       <table className="border-collapse">
//         <thead className='w-96'>
//         <tr className='flex  mt-8'  > 
            
//           <th className=" text-gray-400  text-sm flex items-center  pl-32 pr-28   ">Name 
//           <FaArrowDown  className='text-gray-500'></FaArrowDown>
//           </th>

//           <th className="text-sm  flex items-center  pl-52 "  style={{ color:'#195E8C'}}>Last Edited
//           <FaArrowUp> </FaArrowUp>
//           </th>

//           <th className=" text-gray-400  text-sm flex items-center ml-24 ">Status
//           <FaArrowDown  className='text-gray-500'></FaArrowDown>
//           </th>
          
//         </tr>
    
//         </thead>
//         <tbody>
//         <tr className='bg-gray-100 border border-white '>  
//           <td className="pr-80 bg-blue-100 py-2">Resume-name-example1</td>
//           <td className=" bg-red-100 text-left mr-52 py-2">17/11/2023</td>
//           <td className=" pl-14 pr-4 py-2 text-gray-400">Downloaded</td>
//           <td className=" pl-8 pr-12 py-2 text-blue-300">Edit</td>

//         </tr>
//         <tr className='bg-gray-100 border-8 border-white'>
//           <td className="pl-1 pr-80 py-2">Resume-name-example(2)</td>
//           <td className="px-4 py-2">10/11/2023</td>
//           <td className="px-4 py-2 text-gray-400 ">saved</td>
//           <td className=" pl-8 pr-12 py-2 text-blue-300 ">Edit</td>
// </tr>
//         <tr className='bg-gray-100 border-8 border-white'>
//           <td className="pl-0 pr-80 py-2">AnotherName-for-CV</td>
//           <td className="px-4 py-2">15/09/2023</td>
//           <td className="px-4 py-2 text-gray-400">saved</td>
//           <td className="pl-8 pr-12 py-2 text-blue-300">Edit</td>

//       </tr>
//       </tbody>
//     </table>
//       </div>
//       </div>
//       </div>
//   );
// };
// export default Box; 


function createData(name, lastEdited, status,space,edit) {
  return { name, lastEdited, status,space,edit };
}

const rows = [
  createData('Resume-name-example-1' ,'17/11/2023' , 'Downloaded', ' ','Edit'),
  createData('Resume-name-example(2)', '10/11/2023', 'Saved',' ','Edit'),
  createData('AnotherName-for-CV', '15/09/2023', 'Saved', ' ','Edit'),
];

export default function AccessibleTable() {
  return (

         <div className="flex justify-center items-start h-screen ">
     <div className=" basis-3/4 h-5/6 bg-white z-40 rounded-md shadow-md relative top-0 right-0 mt-[-40px]  p-4 shadow-lg z-10">
         <div className='flex flex-row  items-center' onClick={goBack}>
        <IoArrowBackSharp /> 
         <p className='ml-1 w-9'>  Back</p>
        </div> 
     <h1 className=' w-30 ml-4 mt-2 mb-7 text-2xl'>Edit my Resume</h1> 
     
       <div>
       <p className='mb-7'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt . Ut enim ad minim veniam</p>


    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            {/* <TableCell className='text-gray-100'>Name</TableCell> */}
            <TableCell>
                <span className='text-gray-400 text-sm flex items-center'>Name
                <FaArrowDown  className='text-gray-500 pl-1'></FaArrowDown>
                </span>
                </TableCell>

            <TableCell align="right">
             <span className='text-[#195E8C] text-sm flex items-center'>Last Edited
              <FaArrowUp className='text-[#195E8C] pl-1'> </FaArrowUp>
             </span>   
                </TableCell>
            <TableCell align="right">
                <span className='text-gray-400'>Status</span>
                </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='bg-gray-100'>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell >{row.lastEdited}</TableCell>

              <TableCell align="right">
            <span className="text-gray-400">{row.status}</span>
            </TableCell>


              <TableCell align="right">{row.space}</TableCell>

              <TableCell align="right">
            <Link to="/Profile" className="text-blue-300">{row.edit}</Link>
            </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    </div>
    </div>
  );
}

//jshshshwhwbsbsg