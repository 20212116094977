import React from 'react';
import Profile from './Profile';
import { useNavigate } from 'react-router-dom';

const Header = () => {


  const navigate = useNavigate();

  const goToProfile = () => {
    navigate('/profile');
  };

  const goToHomePage = () =>{
    navigate('/')
  }

    return (
      <div className='p-5 border border-black flex justify-between justify-content align-items'  style={{backgroundColor:'#2A9DEA'}}>
       {/* <p className='upperLeft ' style={{color:'white'}}>UniResume</p> */}
       <img
        src={require('../images/Logo.png')} className="w-15 h-10 "
        onClick={goToHomePage}
        ></img>
               <img
        src={require('../images/Usericon.png')} className="w-15 h-15 mb-10"
   onClick={goToProfile}
        ></img>

      </div>
    );
  };
  
  export default Header;
