import React from 'react';
import Header from './Header.jsx';
import Box from './Box.jsx';

const EditResume = () =>{
    return (
  <div>
   <Header></Header>
    <div className='bg-gray-300'>  
        <Box></Box>
    </div>
  </div>  
    )
}
export default EditResume ; 