
import React,{useState,useEffect} from 'react'
import { CiCircleInfo } from "react-icons/ci";
import { useDispatch} from 'react-redux';
import {navigateToLanguages, navigateToWorkExperience} from '../../Redux/navigationSlice';
import { addCertif } from '../../Redux/CertificateSlice';


const CertificateSix = ({handleSquareClick}) => {

  const dispatch = useDispatch(); //Redux toolkit hook //


  const [title6,setTitle6]= useState("");
  const [organization6,setOrganization6]= useState("");
  const [credentials6,setCredentials6]= useState("");
  const [url6,setUrl6]= useState("");
  const [fromMonth6, setFromMonth6] = useState("");
  const [fromYear6, setFromYear6] = useState("");
  const [toMonth6, setToMonth6] = useState("");
  const [toYear6, setToYear6] = useState("");
  const [certifIsAdded,setCertifIsAdded] = useState(false)

//Store UserCertificates in the redux store //
const handleAddCertif = () => {
if(!certifIsAdded){
  const certif = {
    title6: title6 ||'',
    organization6 : organization6 ||  '',
    credentials6 : credentials6 ||  '',
    url6 : url6 ||  '',
    fromMonth6 : fromMonth6 ||  '',
    fromYear6 : fromYear6 ||  '',
    toMonth6 : toMonth6 ||  '',
    toYear6 : toYear6 ||  '',
  }
  dispatch(addCertif(certif)); 
}
};
/*****************Next Section Button functionnality *********** */
  const handleNextSectionClick = () => {
    handleAddCertif();
    dispatch(navigateToLanguages());
    handleSquareClick(5)
  };
  /**************************Previous Section Button functionnality ***********/
  const handlePreviousSectionClick = () =>{
    dispatch(navigateToWorkExperience());
    handleSquareClick(3)
};



  return (
    <div>
          <h1 className='text-left mb-2 pl-8 mt-10 text-black'>Certificate 6</h1>
          <p className='text-xs text-justify leading-5 mb-4 pl-8 pr-8 text-gray-500'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quia sed officia fugiat.consectetur adipiscing elit. Quia sed officia fugiat.</p>
          <div className='flex flex-col md:flex-row mt-6'>
          <h1 className='text-left text-sm pl-8 font-bold'>Title</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
        </div>
        <div className='mt-4'>
        <input type="text" placeholder='Title' onChange={(e) => setTitle6(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-[550px] h-8 pl-4 ml-8" />
      </div>
      <div className='flex flex-col md:flex-row mt-8'>
        <h1 className='text-left text-sm pl-8 font-bold'> Issueing Organization</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
  
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-20 mt-4 md:mt-0'> Credential ID </h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
   
      </div>
      <div className='flex flex-col md:flex-row gap-6'>
        <input type="text" onChange={(e) => setOrganization6(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-72 h-8 pl-4 ml-8 mt-2" />
        <input type="text" onChange={(e) => setCredentials6(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-52 h-8 pl-4 ml-8 mt-2" />
      </div>

      <div className='flex flex-col md:flex-row mt-6'>
          <h1 className='text-left text-sm pl-8 font-bold'>URL</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
        </div>
        <div className='mt-4'>
        <input type="text" placeholder='URL' onChange={(e) => setUrl6(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-[550px] h-8 pl-4 ml-8" />
      </div>
      
      <div className='flex flex-col md:flex-row mt-10'>
        <h1 className='text-left text-sm pl-8 font-bold'> From </h1>
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-44 mt-4 md:mt-0'> To </h1>
      </div>
      <div className='flex flex-col md:flex-row gap-8'>
        <input type="text" placeholder='Month' onChange={(e) => setFromMonth6(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-28 h-8 pl-2 ml-8 mt-2" />
        <input type="text" placeholder='Year' onChange={(e) => setFromYear6(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-24 h-8 pl-2 mt-2" />
        <input type="text" placeholder='Month' onChange={(e) => setToMonth6(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-28 h-8 pl-2 ml-12 mt-2" />
        <input type="text" placeholder='Year' onChange={(e) => setToYear6(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-24 h-8 pl-2 mt-2" />
      </div>


      <div className='mt-10 gap-12 text-right mr-6'>
      <button className='bg-white px-6 mr-8 z-30 py-2 rounded-md border-2 border-gray-200 relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-gray-200 after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-xs text-gray-500'
       onClick={() => handlePreviousSectionClick()}>Previous Section</button>
        <button className='bg-[#2a9dea] px-8 z-30 py-2 rounded-md text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#34739d] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 [text-shadow:3px_5px_2px_#2a9dea] hover:[text-shadow:2px_2px_2px_#2a9dea] text-xs'
         onClick={() => handleNextSectionClick()}>Next Section</button>
      </div>
      
    </div>
  )
}

export default CertificateSix;

