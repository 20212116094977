import React, {useState,useEffect} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { FaUserGraduate } from "react-icons/fa";
import { TbTargetArrow } from "react-icons/tb";
import { MdOutlineWorkOutline } from "react-icons/md";
import { SlPlus } from "react-icons/sl";
import { PiCertificate } from "react-icons/pi";
import { LuLanguages } from "react-icons/lu";
import { MdOutlineMessage } from "react-icons/md";

import Personalinfo from './Personalinfo';
import EducationSection from './EducationSection';
import Skills from './Skills'; 
import Languages from './Languages';
import Certifications from './Certifications';
import CertificateTwo from './Certificates/CertificateTwo.jsx';
import CertificateThree from './Certificates/CertificateThree.jsx';
import CertificateFour from './Certificates/CertificateFour.jsx';
import CertificateFive from './Certificates/CertificateFive.jsx';
import CertificateSix from './Certificates/CertificateSix.jsx';

import EducationTwo from './AddEducation/EducationTwo.jsx';
import EducationThree from './AddEducation/EducationThree.jsx';


import Workexperience from './Workexperience';
import ExperienceTwo from './WorkExperience/ExperienceTwo.jsx';
import ExperienceThree from './WorkExperience/ExperienceThree.jsx';
import ExperienceFour from './WorkExperience/ExperienceFour.jsx';

import Summary from './Summary';
import Finish from './Finish';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { resetSummary } from '../Redux/summarySlice';
import { navigateToLanguages,navigateToSkills,navigateToEducationSection,
  navigateToSummary,navigateToPersonalInfo,navigateToCertifications,navigateToEducationTwo,navigateToEducationThree,
  navigateToWorkExperience,navigateToWorkTwo, navigateToWorkThree, navigateToWorkFour,
  navigateToCertificateTwo,navigateToCertficateThree,navigateToCertificateFour,navigateToCertificateFive,navigateToCertificateSix, resetNavigation } from '../Redux/navigationSlice';
import Steppers from './Steppers.jsx';
import Header from './Header.jsx'





const Education = () => {

/*select states from the Redux store */
const summaryFinished = useSelector(state => state.summary.summaryFinished); 
const navigateToLanguages = useSelector(state=>state.navigation.navigateToLanguages)
const navigateToSkills = useSelector(state=>state.navigation.navigateToSkills) 
const navigateToEducationSection = useSelector(state=>state.navigation.navigateToEducationSection)

const navigateToCertifications = useSelector(state=>state.navigation.navigateToCertifications)
const navigateToCertificateTwo = useSelector(state => state.navigation.navigateToCertificateTwo)
const navigateToCertificateThree = useSelector(state =>state.navigation.navigateToCertificateThree)
const navigateToCertificateFour = useSelector(state => state.navigation.navigateToCertificateFour)
const navigateToCertificateFive = useSelector(state => state.navigation.navigateToCertificateFive)
const navigateToCertificateSix = useSelector(state =>state.navigation.navigateToCertificateSix)

const navigateToSummary = useSelector(state=>state.navigation.navigateToSummary)
const navigateToEducationTwo = useSelector(state=>state.navigation.navigateToEducationTwo)
const navigateToEducationThree = useSelector(state=>state.navigation.navigateToEducationThree)
const navigateToPersonalInfo = useSelector(state=>state.navigation.navigateToPersonalInfo)

const navigateToWorkExperience = useSelector(state=>state.navigation.navigateToWorkExperience)
const navigateToWorkTwo = useSelector(state=>state.navigation.navigateToWorkTwo)
const navigateToWorkThree = useSelector(state=>state.navigation.navigateToWorkThree)
const navigateToWorkFour = useSelector(state=>state.navigation.navigateToWorkFour)


console.log("skills", navigateToSkills)
// state to define steps of the stepper //
 const [activeStep, setActiveStep] = useState(0) 
 const [customSections, setCustomSections] = useState([]);

 //Access to function of the Redux store //
  const dispatch = useDispatch(); 


  // function to change the steps according to the click on sections //
  const handleSquareClick = (index) => {
    dispatch(resetNavigation());
    setActiveStep(index); 
    dispatch(resetSummary())
   
  }; 

// Section HTML to avoid code repetion //
  const Square = ({ icon,index, text }) => {
    return (
      <div className='hover:border-[#2A9DEA] border-2 border-gray-100 relative bg-transparent p-4 flex flex-col items-center justify-center'
      onClick={() => handleSquareClick(index)}>
        <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full border-2 border-[#2A9DEA]">
          {icon}
        </div>
        <h1 className='text-center text-xs mt-2'>{text}</h1>
      </div>
    )
  };

  // Function to add a new section
  const addNewSection = () => {
    const newSection = { index: customSections.length + 7, text: 'Custom Section', icon: <SlPlus style={{ fontSize: '30px', color: '#2A9DEA' }} /> };
    setCustomSections([...customSections, newSection]);
  };


//Switch function to change Sections according to the stepper //
    const fetchbycase = () => {
   
      switch (activeStep) {
        case 0:
          return <Personalinfo  handleSquareClick={handleSquareClick} />;
        case 1:
          return <EducationSection  handleSquareClick={handleSquareClick}  />;
        case 2:
          return <Skills  handleSquareClick={handleSquareClick}/>;
        case 3:
            return <Workexperience  handleSquareClick={handleSquareClick} />;
        case 4:
            return <Certifications  handleSquareClick={handleSquareClick}/>;
        case 5:
            return  <Languages  handleSquareClick={handleSquareClick}/>;
        case 6 : 
         return  <Summary  handleSquareClick={handleSquareClick}/>;
      
        default:<Personalinfo  handleSquareClick={handleSquareClick}/>;
        }
    };
  



  return (
    <div className='bg-gray-100 h-auto'>
    <div><Header/></div>

    <div className='flex flex-r'>


      {/* First component */}
      <div className='flex items-start mt-[-40px] mx-4'>
        <div className='bg-white w-full sm:w-72 p-1 pb-10 hover:border-[#2A9DEA] border-white border-4'>
          <h1 className='text-left mb-2 pl-3 mt-3'>Fill Section</h1>
          <p className='text-xs text-justify leading-5 mb-4 p-3'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quia sed officia fugiat.
          </p>

          <div className='grid grid-cols-2 gap-0'>
            <Square key={0} index={0} icon={<CheckIcon style={{ fontSize: '30px', color: '#2A9DEA' }} />} text='Personal Info' />
            <Square key={1} index={1} icon={<FaUserGraduate style={{ fontSize: '30px', color: '#2A9DEA' }} />} text='Education' />
            <Square key={2} index={2} icon={<TbTargetArrow style={{ fontSize: '30px', color: '#2A9DEA' }} />} text='Skills' />
            <Square key={3} index={3} icon={<MdOutlineWorkOutline style={{ fontSize: '30px', color: '#2A9DEA' }} />} text='Work Experience' />
            <Square key={4} index={4} icon={<PiCertificate style={{ fontSize: '30px', color: '#2A9DEA' }} />} text='Certificates' />
            <Square key={5} index={5} icon={<LuLanguages style={{ fontSize: '30px', color: '#2A9DEA' }} />} text='Languages' />
            <Square key={6} index={6} icon={<MdOutlineMessage style={{ fontSize: '30px', color: '#2A9DEA' }} />} text='Summary' />
            {customSections.map(section => (
                <Square key={section.index} index={section.index} icon={section.icon} text={section.text} />
              ))}
          </div>
          <button className=' mt-6 w-32 h-10 hover:bg-opacity-60 rounded bg-[#2A9DEA] text-white' onClick={addNewSection}> New Section</button>
        </div>
   
      </div>

      {/* Second component */}
      <div className='flex items-start mt-[-40px] mx-4 mb-14'>
        <div className='bg-white w-full sm:w-[630px] p-1 pb-10 hover:border-[#2A9DEA] border-4'>
          <div className='mt-4'>
            {!summaryFinished && <Steppers activeStep={activeStep}/>}
          </div>
          <div>  {summaryFinished ? <Finish  /> : navigateToLanguages ? <Languages  handleSquareClick={handleSquareClick} /> :
             navigateToSkills? <Skills  handleSquareClick={handleSquareClick}/> :  navigateToWorkExperience? <Workexperience  handleSquareClick={handleSquareClick}/> :  navigateToWorkTwo? <ExperienceTwo  handleSquareClick={handleSquareClick}/> :  navigateToWorkThree? <ExperienceThree  handleSquareClick={handleSquareClick}/> : 
             navigateToWorkFour? <ExperienceFour  handleSquareClick={handleSquareClick}/> 
             : navigateToCertifications? <Certifications  handleSquareClick={handleSquareClick}  />
             : navigateToCertificateTwo? <CertificateTwo handleSquareClick={handleSquareClick}  />
             : navigateToCertificateThree? <CertificateThree handleSquareClick={handleSquareClick}  />
             : navigateToCertificateFour? <CertificateFour handleSquareClick={handleSquareClick}  />
             : navigateToCertificateFive ? <CertificateFive handleSquareClick={handleSquareClick}  />
             : navigateToCertificateSix? <CertificateSix handleSquareClick={handleSquareClick}  />
             :  navigateToEducationSection? <EducationSection  handleSquareClick={handleSquareClick}/> : navigateToEducationTwo? <EducationTwo handleSquareClick={handleSquareClick}/> : navigateToEducationThree? <EducationThree handleSquareClick={handleSquareClick}/>  :  navigateToSummary? <Summary  handleSquareClick={handleSquareClick}/>  :  navigateToPersonalInfo? <Personalinfo  handleSquareClick={handleSquareClick} /> : fetchbycase()}</div>
         
        </div>
      </div>

      {/* Third component */}
      <div className='flex items-start mt-[-40px] mx-4 '>
        <div className='bg-white w-full sm:w-[500px] p-1 pb-10 hover:border-[#2A9DEA] border-4'>
          <h1 className='text-left mb-2 pl-3 mt-3'>Resume Preview</h1>
          <p className='text-xs text-justify leading-5 mb-4 p-3'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quia sed officia fugiat. consectetur adipiscing elit. Quia sed officia fugiat.
          </p>
          <div className='bg-gray-200 w-full sm:w-[400px] h-[500px] ml-10'>
                  </div>
        </div>
      </div>
    </div>
  </div>
  );
}


export default Education;
