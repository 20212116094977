import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { FaArrowRight } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { motion } from 'framer-motion';

const Landing = () => {
  return (
    <div className='flex flex-col md:flex-row mr-10'>
      <div className='bg-gray-300 md:w-1/2 h-screen'>
        <img src='/logpic.png' className='w-full h-screen object-cover' alt='Landing'/>
      </div>
      <div className='bg-white w-full md:w-1/2 max-w-full mt-8 md:mt-0 p-8'>
        <h1 className='text-5xl font-normal mb-8 mt-10 md:mt-14 text-center'>Welcome</h1>
        <h2 className='text-xl font-semibold mb-8 text-center'>to Resume Generator</h2>
        <p className='text-justify mb-8'>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Temporibus optio perferendis maxime quibusdam adipisci deleniti delectus cupiditate sapiente magni?
          Impedit repellendus ab fugiat repellat quasi quisquam eligendi sint inventore reiciendis?</p>
        
        <div className='flex flex-col md:flex-row gap-4 md:gap-20'>
          <NavLink to={'/Login'}>
            <div className='bg-white w-full md:w-72 h-60 rounded-xl border-[#2A9DEA] border-2 border-opacity-50 hover:border-opacity-100 flex flex-col items-center justify-center'>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ rotate: 360, scale: 1 }}
                transition={{ type: "spring", stiffness: 100, damping: 30 }}
                className="w-16 h-16 md:w-20 md:h-20 bg-[#2A9DEA] rounded-full flex items-center justify-center"
              >
                <PersonIcon style={{ fontSize: '30px', color: 'white' }} />
              </motion.div>
              <h1 className="text-center mt-4">Log in / Sign up</h1>
            </div>
          </NavLink>
          <NavLink to={'/GuestMode'}>
            <div className='bg-white w-full md:w-72 h-60 rounded-xl border-[#2A9DEA] border-2 border-opacity-50 hover:border-opacity-100 flex flex-col items-center justify-center'>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ rotate: 360, scale: 1 }}
                transition={{ type: "spring", stiffness: 100, damping: 30 }}
                className="w-16 h-16 md:w-20 md:h-20 bg-[#2A9DEA] rounded-full flex items-center justify-center"
              >
                <FaArrowRight style={{ fontSize: '30px', color: 'white' }} />
              </motion.div>
              <h1 className="text-center mt-4">Continue as Guest</h1>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Landing;
