import React , {useState,useEffect} from 'react';
import { CiCircleInfo } from "react-icons/ci";
import { addLang, deleteLang } from '../Redux/LanguagesSlice';
import {selectLanguageState} from '../Redux/LanguagesSlice';
import { useDispatch,useSelector} from 'react-redux';
import { navigateToCertifications, navigateToSummary} from '../Redux/navigationSlice';

import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

const Languages = ({handleSquareClick}) => {
  
  const dispatch = useDispatch(); //Redux toolkit hook //
  const userLang = useSelector(selectLanguageState);//Redux toolkit hook //

  const [lang1,setLang1]= useState("");
  const [lang2,setLang2]=useState("");
  const [lang3,setLang3] = useState("");
  const [lang4,setLang4] = useState("");
  const [lang5,setLang5] = useState("");
  const [lang6,setLang6] = useState("");

  const [profi1,setProfi1]= useState("");
  const [profi2,setProfi2]=useState("");
  const [profi3,setProfi3]=useState("");
  const [profi4,setProfi4]=useState("");
  const [profi5,setProfi5]=useState("");
  const [profi6,setProfi6]=useState("");

  const [addMoreLang,setAddMoreLang] = useState(false);
  const [frontLang,setFrontLang]=useState([])


//Store UserLanguages in the redux store //
const handleAddlang = () => {
  const languages = {
    lang1: lang1 || userLang.lang1 || '',
    profi1: profi1 || userLang.profi1 || '',
    lang2: lang2 || userLang.lang2 || '',
    profi2 : profi2 || userLang.profi2 || '',
    lang3: lang3 || userLang.lang3 || '',
    profi3 : profi3 || userLang.profi3 || '',
    lang4: lang4 || userLang.lang4 || '',
    profi4 : profi4 || userLang.profi4 || '',
    lang5: lang5 || userLang.lang5 || '',
    profi5 : profi5 || userLang.profi5 || '',
    lang6: lang6 || userLang.lang6 || '',
    profi6 : profi6 || userLang.profi6 || '',
  }

  dispatch(addLang(languages)); 
  console.log("Data",languages)
};

/*****************Next Section Button functionnality **************** */
  const handleNextSectionClick = () => {
    handleAddlang()
    dispatch(navigateToSummary());
    handleSquareClick(6)
  };
  /**************************Previous Section Button functionnality ***********/
  const handlePreviousSectionClick = () =>{
    dispatch(navigateToCertifications());
    handleSquareClick(4)
};

// Delete a Language from the redux store //
const handleDeleteLang = (x) => {
  const updatedLangs = frontLang.filter(lang => lang !== x);
  setFrontLang(updatedLangs); 
  switch (x) {
    case lang1:
      setLang1("");
      setProfi1("");
      break;
    case lang2:
      setLang2("");
      setProfi2("");
      break;
    case lang3:
      setLang3("");
      setProfi3("");
      break;
    case lang4:
      setLang4("");
      setProfi4("");
      break;
    case lang5:
      setLang5("");
      setProfi5("");
      break;
    case lang6:
      setLang6("");
      setProfi6("");
      break;
    default:
      break;
  }

  dispatch(deleteLang(x));    
};



useEffect(() => {
  const langs = [lang1, lang2, lang3, lang4, lang5, lang6];
  setFrontLang(langs.filter(lang => lang));
}, [lang1, lang2, lang3, lang4, lang5, lang6]);

console.log("frontlang",frontLang)

  return (
    <div>
        <h1 className='text-left mb-2 pl-8 mt-10 text-black'>Languages</h1>
          <p className='text-xs text-justify leading-5 mb-4 pl-8 pr-8 text-gray-500'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quia sed officia fugiat.consectetur adipiscing elit. Quia sed officia fugiat.</p>

    <div className='flex flex-col md:flex-row mt-8'>
        <h1 className='text-left text-sm pl-8 font-bold'>Language</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
  
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-40 mt-4 md:mt-0'> Proficiency</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
   
      </div>
      <div className='flex flex-col md:flex-row gap-8'>
        <input type="text" onChange={(e) => setLang1(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-72 h-8 pl-4 ml-8 mt-2" />
        <input type="text" onChange={(e) => setProfi1(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-48 h-8 pl-4 ml-8 mt-2" />
      </div>   



      <div className='flex flex-col md:flex-row mt-8'>
        <h1 className='text-left text-sm pl-8 font-bold'>Language</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
  
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-40 mt-4 md:mt-0'> Proficiency</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
   
      </div>
      <div className='flex flex-col md:flex-row gap-8'>
        <input type="text" onChange={(e) => setLang2(e.target.value)}  className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-72 h-8 pl-4 ml-8 mt-2" />
        <input type="text" onChange={(e) => setProfi2(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-48 h-8 pl-4 ml-8 mt-2" />
      </div> 

      {addMoreLang && <div>
        
        {/***********************************Third Language*********************************/}
        <div className='flex flex-col md:flex-row mt-8'>
        <h1 className='text-left text-sm pl-8 font-bold'>Language</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
  
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-40 mt-4 md:mt-0'> Proficiency</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
   
      </div>
        <div className='flex flex-col md:flex-row gap-8'>
        <input type="text" onChange={(e) => setLang3(e.target.value)}  className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-72 h-8 pl-4 ml-8 mt-2" />
        <input type="text" onChange={(e) => setProfi3(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-48 h-8 pl-4 ml-8 mt-2" />
      </div>

   {/***********************************Forth Language*********************************/}
   <div className='flex flex-col md:flex-row mt-8'>
        <h1 className='text-left text-sm pl-8 font-bold'>Language</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
  
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-40 mt-4 md:mt-0'> Proficiency</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
   
      </div>
     <div className='flex flex-col md:flex-row gap-8'>
      <input type="text" onChange={(e) => setLang4(e.target.value)}  className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-72 h-8 pl-4 ml-8 mt-2" />
      <input type="text" onChange={(e) => setProfi4(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-48 h-8 pl-4 ml-8 mt-2" />
    </div>
   {/***********************************Fifth Language*********************************/}
   <div className='flex flex-col md:flex-row mt-8'>
        <h1 className='text-left text-sm pl-8 font-bold'>Language</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
  
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-40 mt-4 md:mt-0'> Proficiency</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
   
      </div>
     <div className='flex flex-col md:flex-row gap-8'>
    <input type="text" onChange={(e) => setLang5(e.target.value)}  className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-72 h-8 pl-4 ml-8 mt-2" />
    <input type="text" onChange={(e) => setProfi5(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-48 h-8 pl-4 ml-8 mt-2" />
  </div>

  {/***********************************Sixth Language*********************************/}
  <div className='flex flex-col md:flex-row mt-8'>
        <h1 className='text-left text-sm pl-8 font-bold'>Language</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
  
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-40 mt-4 md:mt-0'> Proficiency</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
   
      </div>
    <div className='flex flex-col md:flex-row gap-8'>
        <input type="text" onChange={(e) => setLang6(e.target.value)}  className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-72 h-8 pl-4 ml-8 mt-2" />
        <input type="text" onChange={(e) => setProfi6(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-48 h-8 pl-4 ml-8 mt-2" />
      </div></div>}

      <div className='flex flex-col md:flex-row ml-6 mt-14'>
      <button className='bg-[#2a9dea] px-8 z-30 py-2 rounded-xl text-white relative font-semibold font-sans   bg-opacity-70 hover:bg-opacity-100 text-xs' onClick={()=>{setAddMoreLang(!addMoreLang)}}> <span className=' text-sm'>+</span> Add Languages </button>
      </div> 

      <div className='w-[570px] flex flex-wrap gap-3 h-auto ml-8 mr-8 mb-14 mt-8'>
           
           
            {
             frontLang.map((el,i)=>{
                return(
                <div><Button variant="outlined" startIcon={<DeleteIcon />} onClick={()=>{handleDeleteLang(el)}}>
             {el} </Button></div>)
              })
            }
          </div>

      <div className='mt-10 gap-12 text-right mr-6'>
      <button className='bg-white px-6 mr-8 z-30 py-2 rounded-md border-2 border-gray-200 relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-gray-200 after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-xs text-gray-500 '
      onClick={() => handlePreviousSectionClick()}>Previous Section</button>
       <button className='bg-[#2a9dea] px-8 z-30 py-2 rounded-md text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#34739d] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 [text-shadow:3px_5px_2px_#2a9dea] hover:[text-shadow:2px_2px_2px_#2a9dea] text-xs'
         onClick={handleNextSectionClick}>Next Section</button>
      </div>

    </div>
  )
}

export default Languages;

