import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector} from 'react-redux';
import { navigateToEducationSection, navigateToWorkExperience} from '../Redux/navigationSlice';
import { addSkill, deleteSkill } from '../Redux/skillSlice'
import { selectSkillsState } from '../Redux/skillSlice';
import { TiDeleteOutline } from "react-icons/ti";
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';


const Skills = ({handleSquareClick}) => {

  const [skill,setSkill]=useState("")
  const [frontskill,setFrontSkill]=useState([])
  const dispatch = useDispatch(); 
  const skills = useSelector(selectSkillsState); //To select skills from the store (to get data stored)

  /********One Skill html to avoid repetition*********** */
const OneSkill = ({text }) => {
  return(
    <div className='bg-gray-100 rounded-full h-10 shadow-md p-2 w-32  mt-4 text-sm  border-gray-100 border-2'>
      {text}
       </div>
  )
};


//Store skill in the redux store //
const handleAddSkill = () => {
  if (skill.trim() !== '') {
    dispatch(addSkill(skill.trim())); 
    setSkill('');
  }
};

// Delete a skill from the redux store //
const handleDeleteSkill = (x) => {
  for (let i = 0; i < frontskill.length; i++) {
    if (frontskill[i] === x) {
        frontskill.splice(i, 1)}}
  dispatch(deleteSkill(x));
}

//When the user click on the "enter" in keyboard the skill will be updated //
const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    setFrontSkill(prevSkills => [...prevSkills, skill.trim()]);
    event.preventDefault();
    handleAddSkill();
  }
};

  /*****************Next Section Button functionnality *********** */
const handleNextSectionClick = () => {
  dispatch(navigateToWorkExperience());
  handleSquareClick(3)
};
  /**************************Previous Section Button functionnality ***********/
  const handlePreviousSectionClick = () =>{
    dispatch(navigateToEducationSection());
    handleSquareClick(1)
};

//Keep an eye on skills when updated and log it on the console to verify //
useEffect(() => {
  console.log(skills.skillsList);
}, [skills]);

  return (
    <div>
       <h1 className='text-left mb-2 pl-8 mt-10 text-black'>Skills</h1>
          <p className='text-xs text-justify leading-5 mb-4 pl-8 pr-8'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quia sed officia fugiat.consectetur adipiscing elit. Quia sed officia fugiat.</p>
          <h1 className='text-left text-sm pl-8 font-bold'>My Skills</h1>
          <div className='mt-4'><textarea 
          className='w-[550px] h-[100px] border-2 border-gray-200 bg-gray-50' 
          value={skill}
          onChange={(ev) => setSkill(ev.target.value)}
          onKeyDown={handleKeyDown}></textarea></div>
          <div className='w-[570px] flex flex-wrap gap-3 h-auto ml-8 mr-8 mb-14 mt-8'>
            {
              frontskill.map((el,i)=>{
                return(
                <div><Button variant="outlined" startIcon={<DeleteIcon />} onClick={()=>{handleDeleteSkill(el)}}>
             {el} </Button></div>)
              })
            }
          </div>
          <h1 className='text-left text-sm pl-8 font-bold mt-6'>Examples</h1>
          <div className='w-[570px] flex flex-wrap gap-3 h-auto ml-8 mb-14'><OneSkill text='Javascript'/>
                < OneSkill text='React'/><OneSkill text='php'/><OneSkill text='English'/><OneSkill text='Communication'/><OneSkill text='Pack Office'/><OneSkill text='Management'/>
          </div>
    <div className='mt-14 gap-12 text-right mr-6'>
        <button className='bg-white px-6 mr-8 z-30 py-2 rounded-md border-2 border-gray-200 relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-gray-200 after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-xs text-gray-500 '
             onClick={() => handlePreviousSectionClick()}>Previous Section</button>
        <button className='bg-[#2a9dea] px-8 z-30 py-2 rounded-md text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#34739d] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 [text-shadow:3px_5px_2px_#2a9dea] hover:[text-shadow:2px_2px_2px_#2a9dea] text-xs'
             onClick={() => handleNextSectionClick()}>Next Section</button>
    </div>
    </div>
  )
}

export default Skills;
