import './App.css';
import {Routes, Route} from 'react-router-dom';
import Landing from './Components/Landing.jsx';
import Login from './Components/Login.jsx';
import SignUp from './Components/Signup.jsx';
import GuestMode from './Components/GuestMode.jsx'
import Education from './Components/Education.jsx'
import EditResume from './Components/EditResume.jsx';
import Profile from './Components/Profile.jsx';
import Savemem from './Components/Savemem.jsx'
import SaveGuest from './Components/SaveGuest.jsx';
import Download from './Components/Download.jsx';
import Downloading from './Components/Downloading.jsx';
import Success from './Components/Success.jsx';
import Problem from './Components/Problem.jsx';
import ResumeOptions from './Components/ResumeOptions.jsx';


function App() {
  return (
    <div className='App'>
  <Routes>
          <Route path='/' element={<Landing/>}></Route>
          <Route path='/Login' element={<Login/>}></Route>
          <Route path='/Signup' element={<SignUp/>}></Route>
          <Route path='/GuestMode' element={<GuestMode/>}></Route>
          <Route path='/Education' element={<Education/>}></Route>
          <Route path='/Edit' element={<EditResume/>}></Route>
           <Route path='/Profile' element={<Profile/>}> </Route>
           <Route path='/Savemem' element={<Savemem/>}> </Route>
           <Route path='/SaveGuest' element={<SaveGuest/>}> </Route>
           <Route path='/Download' element={<Download/>}> </Route>
           <Route path='/Downloading' element={<Downloading/>}> </Route>
           <Route path='/Success' element={<Success/>}> </Route>
           <Route path='/Problem' element={<Problem/>}> </Route>
           <Route path='/ResumeOptions' element={<ResumeOptions/>}> </Route>
           


  </Routes>
    </div>
  );
}

export default App;
