import React,{useState,useEffect} from 'react'
import { CiCircleInfo } from "react-icons/ci";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaBold, FaItalic, FaUnderline, FaStrikethrough } from "react-icons/fa";
import { MdEmojiEmotions } from "react-icons/md";
import { useDispatch,useSelector} from 'react-redux';
import { navigateToEducationSection } from '../Redux/navigationSlice';
import { addInfo } from '../Redux/personalInfoSlice';
import { selectInfoState } from '../Redux/personalInfoSlice';



const Personalinfo = ({handleSquareClick}) => {


  const [firstName,setFirstName]= useState("");
  const [lastName,setLastName]= useState("");
  const [city,setCity]= useState("");
  const [country,setCountry]= useState("");
  const [email,setEmail] = useState("");
  const [phoneNumber,setPhoneNumber] = useState("");
// For text editor tools // 
const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
const onEditorStateChange = (newEditorState) => {
  setEditorState(newEditorState)};
const editorText = editorState.getCurrentContent().getPlainText();




const dispatch = useDispatch(); //Redux toolkit hook //
const userInfo = useSelector(selectInfoState);


//Store UserInfo in the redux store //
const handleAddINfo = () => {
  const personalInf = {
    firstName: firstName || userInfo.firstName || '',
    lastName: lastName || userInfo.lastName || '',
    city: city || userInfo.city || '',
    country : country || userInfo.country || '',
    email: email || userInfo.email || '',
    phoneNumber: phoneNumber || userInfo.phoneNumber || '',
    editorText : editorText || userInfo.editorText || ''
  }
  dispatch(addInfo(personalInf)); 
  console.log("Data",personalInf)
};


 /***********Next Section button Functionnality *********** */
const handleNextSectionClick = () => {
  handleAddINfo()
  dispatch(navigateToEducationSection());
  handleSquareClick(1)
};

 useEffect(() => {
  console.log( "Data stored in Redux",userInfo.InfoList);
}, [userInfo]);

return (
    <div className='bg-white'>
       <h1 className='text-left mb-2 pl-8 mt-10'>Personal Info</h1>
          <p className='text-xs text-justify leading-5 mb-4 pl-8 pr-8 text-gray-500 '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quia sed officia fugiat.consectetur adipiscing elit. Quia sed officia fugiat.</p>
    {/**First name last name */}       
         <div className='flex flex-r'>
         <h1 className='text-left text-sm pl-8 font-bold'>First Name</h1>
         <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15}/></div>
            <h1 className='text-left text-sm pl-8 font-bold ml-44'>Last Name</h1>
         <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15}/></div>
         </div>

         <div className='flex flex-r gap-8'>
         <input type="text"   name="firstName"  onChange={(e) => setFirstName(e.target.value)}  className="relative  bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600  focus:bg-white block w-60 h-8 pl-4 ml-8 mt-2 "/>
         <input type="text"  name="lastName"    onChange={(e) => setLastName(e.target.value)} className="relative  bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600  focus:bg-white block w-60 h-8 pl-4 ml-8 mt-2 "/>
      </div>
    {/**City and Country */}
       <div className='flex flex-r mt-10'>
       <h1 className='text-left text-sm pl-8 font-bold'>City</h1>
         <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15}/></div>
            <h1 className='text-left text-sm pl-20 font-bold ml-44'>Country</h1>
         <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15}/></div>
      </div> 

      <div className='flex flex-r gap-8'>
         <input type="text" name="city" onChange={(e) => setCity(e.target.value)} className="relative  bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600  focus:bg-white block w-60 h-8 pl-4 ml-8 mt-2 "/>
         <input type="text" name="country" onChange={(e) => setCountry(e.target.value)} className="relative  bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600  focus:bg-white block w-60 h-8 pl-4 ml-8 mt-2 "/>
      </div>
   {/**Email nad phone number */}
      <div className='flex flex-r mt-10'>
       <h1 className='text-left text-sm pl-8 font-bold'>Email</h1>
         <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15}/></div>
            <h1 className='text-left text-sm pl-20 font-bold ml-44'>Phone Number</h1>
         <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15}/></div>
      </div> 
      
      <div className='flex flex-r gap-8'>
         <input type="text" name="email" onChange={(e) => setEmail(e.target.value)}  className="relative  bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600  focus:bg-white block w-60 h-8 pl-4 ml-8 mt-2 "/>
         <input type="text" name="phoneNumber" onChange={(e) => setPhoneNumber(e.target.value)}  className="relative  bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600  focus:bg-white block w-60 h-8 pl-4 ml-8 mt-2 "/>
      </div>

<div className='ml-6 mr-6 mt-8 bg-gray-100 min-h-44'> 
<Editor

        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      
        toolbar={{
          options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
            bold: <FaBold/>,
            italic: <FaItalic/>,
            underline: <FaUnderline/>,
            strikethrough: <FaStrikethrough />,
            emoji : < MdEmojiEmotions/>,
          },
          
          fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
          },
          
        }}
      />
</div>
<div className='flex flex-r ml-6 mr-6 mt-1 text-xs text-gray-500 text-left leading-6'><span className='font-bold text-left mr-1'>TIP: </span> 
<p>I took Computer Science classes like : this and this and that during my first year and then i did this and this and joigned an amazing club. </p></div>
<div className='mt-14 gap-12 text-right mr-6'>
<button className='bg-white px-14 mr-8 z-30 py-2 rounded-md border-2 border-gray-200 text-gray-500 relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-gray-200 after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-xs '>Back</button>
<button className='bg-[#2a9dea] px-8 z-30 py-2 rounded-md text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#34739d] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full
 after:rounded-md after:hover:scale-[300] after:hover:transition-all
  after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 [text-shadow:3px_5px_2px_#2a9dea] 
  hover:[text-shadow:2px_2px_2px_#2a9dea] text-xs' 
  onClick={() =>{handleNextSectionClick()}}>Next Section</button>
    </div>
    </div>
  )
}

export default Personalinfo;
