import React, { useState,useEffect } from 'react';
import { CiCircleInfo } from "react-icons/ci";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaBold, FaItalic, FaUnderline, FaStrikethrough } from "react-icons/fa";
import { MdEmojiEmotions } from "react-icons/md";
import { useDispatch} from 'react-redux';
import { navigateToPersonalInfo, navigateToSkills, navigateToEducationThree } from '../../Redux/navigationSlice';
import { addEd } from '../../Redux/EducationSlice';


const EducationTwo = ({handleSquareClick}) => {


  const [degreeAndMajor2, setDegreeAndMajor2] = useState('');
  const [university2, setUniversity2] = useState('');
  const [location2, setLocation2] = useState('');
  const [fromMonth2, setFromMonth2] = useState('');
  const [fromYear2, setFromYear2] = useState('');
  const [toMonth2, setToMonth2] = useState('');
  const [toYear2, setToYear2] = useState('');
  const [educationIsAdded, setEducationIsAdded] = useState(false); // Make sure that data is added only once either with "addEducation button" or with "NextSection button" //
  const dispatch = useDispatch(); //Redux toolkit hook 


/***********States for the Text editor ***** */
const [editorState2, setEditorState2] = useState(() => EditorState.createEmpty());
const onEditorStateChange = (newEditorState) => {
 setEditorState2(newEditorState)}
 const editorText2 = editorState2.getCurrentContent().getPlainText();


//Store UserEducation in the redux store //
const handleAddEducation = () => {
  if(!educationIsAdded){
  const Edu = {
    degreeAndMajor2: degreeAndMajor2 ||  '',
    university2: university2 ||  '',
    location2: location2 ||  '',
    fromMonth2: fromMonth2 ||  '',
    fromYear2: fromYear2 || '',
    toMonth2: toMonth2 ||  '',
    toYear2: toYear2 || '',
    editorText2 : editorText2 || ''
  }
  dispatch(addEd(Edu)); 
  console.log("Data",Edu)
  setEducationIsAdded(true)}
};

  /*****************Next Section Button functionnality *********** */
   const handleNextSectionClick = () => {
      handleAddEducation()
      dispatch(navigateToSkills());
      handleSquareClick(2)
    };

/**************************Previous Section Button functionnality ***********/
  const handlePreviousSectionClick = () =>{
     dispatch(navigateToPersonalInfo());
     handleSquareClick(0)
}

/*******************************Register Education 2 and navigate to Education 3 ************* */
const handleAddEducation2Click = () => {
  handleAddEducation();
  dispatch(navigateToEducationThree());
  console.log("Education 2 ok");
};


return (
    
    <div className='bg-white'>
      <h1 className='text-left mb-2 pl-8 mt-10 text-black'>Education 2</h1>
      <p className='text-xs text-justify leading-5 mb-4 pl-8 pr-8'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quia sed officia fugiat.consectetur adipiscing elit. Quia sed officia fugiat.</p>

      {/**Degree and Major */}
      <div className='flex flex-col md:flex-row'>
        <h1 className='text-left text-sm pl-8 font-bold'>Degree And Major</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
      </div>
      <div className='mt-4'>
        <input type="text" value={degreeAndMajor2}
          onChange={(e) => setDegreeAndMajor2(e.target.value)}
           className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-[550px] h-8 pl-4 ml-8" />
      </div>

      {/**University Location*/}
      <div className='flex flex-col md:flex-row mt-10'>
        <h1 className='text-left text-sm pl-8 font-bold'> University </h1>
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-44 mt-4 md:mt-0'> Location </h1>
      </div>
      <div className='flex flex-col md:flex-row gap-8'>
        <input type="text" 
         value={university2}
         onChange={(e) => setUniversity2(e.target.value)}
        className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-72 h-8 pl-4 ml-8 mt-2" />
        <input 
         value={location2}
         onChange={(e) => setLocation2(e.target.value)}
        type="text" className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-52 h-8 pl-4 ml-8 mt-2" />
      </div>

      {/*From To */}
      <div className='flex flex-col md:flex-row mt-10'>
        <h1 className='text-left text-sm pl-8 font-bold'> From </h1>
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-44 mt-4 md:mt-0'> To </h1>
      </div>
      <div className='flex flex-col md:flex-row gap-8'>
        <input
         value={fromMonth2}
         onChange={(e) => setFromMonth2(e.target.value)}
        type="text" placeholder='Month' className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-28 h-8 pl-2 ml-8 mt-2" />
        <input 
          value={fromYear2}
          onChange={(e) => setFromYear2(e.target.value)}
       type="text" placeholder='Year' className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-24 h-8 pl-2 mt-2" />
        <input 
          value={toMonth2}
          onChange={(e) => setToMonth2(e.target.value)}
      type="text" placeholder='Month' className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-28 h-8 pl-2 ml-12 mt-2" />
        <input
          value={toYear2}
          onChange={(e) => setToYear2(e.target.value)}
       type="text" placeholder='Year' className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-24 h-8 pl-2 mt-2" />
      </div>

      <div className='ml-6 mr-6 mt-8 bg-gray-100 min-h-44'>
        <Editor
          editorState={editorState2}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
              bold: <FaBold />,
              italic: <FaItalic />,
              underline: <FaUnderline />,
              strikethrough: <FaStrikethrough />,
              emoji: <MdEmojiEmotions />,
            },

            fontFamily: {
              options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
            },

          }}
        />
      </div>
      
      <div className='flex flex-col md:flex-row ml-6 mr-6 mt-1 text-xs text-gray-500 text-left leading-6'>
        <span className='font-bold text-left mr-1 '>TIP: </span>
        <p>I took Computer Science classes like: this and this and that during my first year and then I did this and this and joined an amazing club. </p>
      </div>

      <div className='flex flex-col md:flex-row ml-6 mt-8 '><button className='bg-[#2a9dea] px-8 z-30 py-2 rounded-xl text-white relative font-semibold font-sans   bg-opacity-70 hover:bg-opacity-100 text-xs' onClick={handleAddEducation2Click}> <span className=' text-sm'>+</span> Add Education </button>
      </div>
          <div className='mt-14 gap-12 text-right mr-6'>
        <button className='bg-white px-6 mr-8 z-30 py-2 rounded-md border-2 border-gray-200 relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-gray-200 after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-xs text-gray-500 '
          onClick={() => handlePreviousSectionClick()}>Previous Section</button>
        <button className='bg-[#2a9dea] px-8 z-30 py-2 rounded-md text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#34739d] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 [text-shadow:3px_5px_2px_#2a9dea] hover:[text-shadow:2px_2px_2px_#2a9dea] text-xs'
          onClick={() => handleNextSectionClick()}>Next Section</button>
      </div>
    </div>
  )
}

export default EducationTwo;
