import React,  { useState,useEffect }  from 'react';
import { CiCircleInfo } from "react-icons/ci";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaBold, FaItalic, FaUnderline, FaStrikethrough } from "react-icons/fa";
import { MdEmojiEmotions } from "react-icons/md";

import { useDispatch,useSelector} from 'react-redux';
import { navigateToCertifications, navigateToSkills, navigateToWorkTwo} from '../Redux/navigationSlice';
import { addWork } from '../Redux/workSlice';
import {selectWorkState} from '../Redux/workSlice';


const Workexperience = ({handleSquareClick}) => {
  const dispatch = useDispatch(); // Redux toolkit hook //
  const userWork = useSelector(selectWorkState);

const [job,setJob]= useState("");
const [company,setCompany]=useState("");
const [location,setLocation]= useState("");
const [fromMonth, setFromMonth] = useState("");
const [fromYear, setFromYear] = useState("");
const [toMonth, setToMonth] = useState("");
const [toYear, setToYear] = useState("");
/*********States for the text editor ********* */
const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
const onEditorStateChange = (newEditorState) => {
  setEditorState(newEditorState)};
const editorText = editorState.getCurrentContent().getPlainText();




  console.log("userWork",userWork)
  
//Store UserWork in the redux store //
const handleAddWork = () => {
  const work = {
    job: job || userWork.job || '',
    company: company || userWork.company || '',
    location: location || userWork.location || '',
    fromMonth: fromMonth || userWork.fromMonth || '',
    fromYear: fromYear || userWork.fromYear || '',
    toMonth: toMonth || userWork.toMonth || '',
    toYear: toYear || userWork.toYear || '',
    editorText : editorText || userWork.editorText || ''
  }
  dispatch(addWork(work)); 
  console.log("Data",work)
};


 /*****************Next Section Button functionnality *********** */
const handleNextSectionClick = () => {
  handleAddWork()
  dispatch(navigateToCertifications());
  handleSquareClick(4)
};

/**************************Previous Section Button functionnality ***********/
  const handlePreviousSectionClick = () =>{
    dispatch(navigateToSkills());
    handleSquareClick(2)
};

/*******************************Register work 1 and navigate to work 2 ************* */
const handleAddWorkClick = () => {
  handleAddWork();
  dispatch(navigateToWorkTwo());
};

useEffect(() => {
  console.log( "Work",userWork.workList);
}, [userWork]);

  return (
    <div>
       <h1 className='text-left mb-2 pl-8 mt-10 text-black'>Work experience</h1>
          <p className='text-xs text-justify leading-5 mb-4 pl-8 pr-8 text-gray-500'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quia sed officia fugiat.consectetur adipiscing elit. Quia sed officia fugiat.</p>
          <div className='flex flex-col md:flex-row mt-6'>
          <h1 className='text-left text-sm pl-8 font-bold'>Job Title</h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
        </div>
        <div className='mt-4'>
        <input type="text" placeholder='Job title'  onChange={(e) => setJob(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-[550px] h-8 pl-4 ml-8" />
      </div>


      <div className='flex flex-col md:flex-row mt-8'>
        <h1 className='text-left text-sm pl-8 font-bold'> Company </h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
  
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-40 mt-4 md:mt-0'> Location </h1>
        <div className='mt-1 ml-2 text-gray-500'><CiCircleInfo size={15} /></div>
   
      </div>
      <div className='flex flex-col md:flex-row gap-8'>
        <input type="text" placeholder='Company' onChange={(e) => setCompany(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-72 h-8 pl-4 ml-8 mt-2" />
        <input type="text" placeholder='Location' onChange={(e) => setLocation(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-52 h-8 pl-4 ml-8 mt-2" />
      </div>

      {/*From To */}
      <div className='flex flex-col md:flex-row mt-10'>
        <h1 className='text-left text-sm pl-8 font-bold'> From </h1>
        <h1 className='text-left text-sm pl-0 md:pl-28 font-bold md:ml-44 mt-4 md:mt-0'> To </h1>
      </div>
      <div className='flex flex-col md:flex-row gap-8'>
        <input type="text" placeholder='FromMonth'   onChange={(e) => setFromMonth(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-28 h-8 pl-2 ml-8 mt-2" />
        <input type="text" placeholder='FromYear'   onChange={(e) => setFromYear(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-24 h-8 pl-2 mt-2" />
        <input type="text" placeholder='ToMonth'   onChange={(e) => setToMonth(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-28 h-8 pl-2 ml-12 mt-2" />
        <input type="text" placeholder='ToYear'   onChange={(e) => setToYear(e.target.value)} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s focus:ring-gray-600 focus:bg-white block w-full md:w-24 h-8 pl-2 mt-2" />
      </div>

      <div className='ml-6 mr-6 mt-8 bg-gray-100 min-h-44'>
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
              bold: <FaBold />,
              italic: <FaItalic />,
              underline: <FaUnderline />,
              strikethrough: <FaStrikethrough />,
              emoji: <MdEmojiEmotions />,
            },

            fontFamily: {
              options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
            },

          }}
    
        />
      </div>
      <div className='flex flex-col md:flex-row ml-6 mr-6 mt-1 text-xs text-gray-500 text-left leading-6'>
        <span className='font-bold text-left mr-1'>TIP: </span>
        <p className=' text-left text-gray-500'>I took Computer Science classes like: this and this and that during my first year and then I did this and this and joined an amazing club. </p>
      </div>
      <div className='flex flex-col md:flex-row ml-6 mt-8'>
      <button className='bg-[#2a9dea] px-8 z-30 py-2 rounded-xl text-white relative font-semibold font-sans   bg-opacity-70 hover:bg-opacity-100 text-xs' onClick={handleAddWorkClick}> <span className=' text-sm'>+</span> Add Experience </button>
      </div> 
      <div className='mt-10 gap-12 text-right mr-6'>
      <button className='bg-white px-6 mr-8 z-30 py-2 rounded-md border-2 border-gray-200 relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-gray-200 after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-xs text-gray-500 '
      onClick={() => handlePreviousSectionClick()}>Previous Section</button>
         <button className='bg-[#2a9dea] px-8 z-30 py-2 rounded-md text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#34739d] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 [text-shadow:3px_5px_2px_#2a9dea] hover:[text-shadow:2px_2px_2px_#2a9dea] text-xs'
      onClick={() => {handleNextSectionClick()}}>Next Section</button>
      </div>


    </div>
  )
}

export default Workexperience;

