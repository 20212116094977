import React, { useState } from 'react';
import { MdOutlineMailOutline } from "react-icons/md";
import { HiOutlineLockClosed } from "react-icons/hi";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import { NavLink } from "react-router-dom";


const Login = () => {
  const [showPassword, setShowPassword] = useState(false);  // state to show and hide the password of user //
  const [checked, setChecked] = useState(false);    // state to handle the checking of remember me //

  const handleChange = (event) => {setChecked(event.target.checked)} 

  return (
    <div className='flex flex-r'>
      <div className='bg-gray-300 h-screen'>
        <img src='/logpic.png' className='w-full h-screen object-cover' alt='Login'/>
      </div>
      <div className='bg-white w-[50%] max-w-full mt-8 ml-4 md:ml-28 mr-4 md:mr-24'>
        <h1 className='text-5xl font-normal mb-10 mt-4 '> Log in </h1>
        <h2 className='text-2xl font-normal mb-6'>to Resume Generator</h2>
        <h3>Don't have an account ?  <NavLink to={'/Signup'} ><span className='text-[#2A9DEA]'>Sign up here </span></NavLink></h3>
         
    {/**EmailInput */}
    <div className="relative w-[55%] max-w-full mt-16 ml-4 md:ml-44 mr-4 md:mr-24">
          <input type="text" className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s rounded-lg focus:ring-[#2A9DEA]  focus:bg-white focus:border-[#2A9DEA] block w-full h-12 p-2.5 pl-10 " placeholder=  " Email"/>
          <MdOutlineMailOutline className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400 mr-3"/>
        </div>
    {/**Password Input */}

    <div className="relative w-[55%] max-w-full mt-6 ml-4 md:ml-44 mr-4 md:mr-24">
          <div className="relative">
            <input type={showPassword ? "text" : "password"} className="relative bg-gray-100 ring-0 outline-none border border-neutral-300 text-neutral-700 placeholder-gray-400 text-s rounded-lg focus:ring-[#2A9DEA]  focus:bg-white focus:border-[#2A9DEA] block w-full h-12 p-2.5 pl-11 " placeholder=  "Password"/>
            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="absolute right-2 top-1/2  transform -translate-y-1/2 cursor-pointer text-gray-400"/> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"/>}
          </div>
          <HiOutlineLockClosed className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400 mr-3"/>
        </div>

  {/**Remember me checkbox */}
        <div className=' ml-6 mt-3 w-[50%] max-w-full md:ml-12 '>
        <Checkbox style={{color : checked ? "#2A9DEA" : "gray"}}
      checked={checked}
      onChange={handleChange}/><span className='text-gray-800'> remember me </span>
   
      </div>
   {/**Login button */}
      <div className='mt-14'>
      <button  className='bg-[#2A9DEA] hover:bg-opacity-80 w-1/4 text-white font-semibold h-12 rounded-xl' ><AddIcon className='mr-2'/>
        Log In 
      </button>
      </div>
     
     
      </div>
    </div>
  )
}

export default Login;
