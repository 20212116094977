import { createSlice } from '@reduxjs/toolkit';

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    LanguageList: [],
  },
  reducers: {
    addLang : (state, action) => {
        console.log('Payload received:', action.payload);
        state.LanguageList = [...state.LanguageList, action.payload];
        console.log('Updated LanguageList:', state.LanguageList);   
    },
    deleteLang: (state, action) => {
      console.log('Payload received for deletion:', action.payload);
      state.LanguageList = state.LanguageList.filter(lang => lang !== action.payload);
      console.log('Updated LanguageList after deletion:', state.LanguageList);
  }}
  
});

export const { addLang, deleteLang } = languageSlice.actions;

export const selectLanguageState = (state) => state.language;

export default languageSlice.reducer;
