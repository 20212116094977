import React , { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export default function SimpleContainer() {
    const [isCanceled, setIsCanceled] = useState(false);
    const handleCancel = () => {
        setIsCanceled(true); }
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Box sx={{ bgcolor: 'white', height: '60vh' }} className='border border-black rounded-lg' >
            <h1 className='font-bold flex justify-between items-center '>
                <span className="ml-80 text-lg">Save my Resume  </span>
            <IconButton className='ml-auto'>
            <ClearIcon/>
              </IconButton>
            </h1>
            <div className='text-left pt-8 px-8'>Your resume will be saved to your profile under the name: </div>

            <Box
      sx={{
        width: 900,
        maxWidth: '100%',
      }}
      className='pt-6 px-8 '
    >
      <TextField  placeholder="Canadian-Resume31012023" style={{ width:'100%' }} id="fullWidth"
      InputProps={{
        endAdornment: (
          <IconButton>
            <ClearIcon />
          </IconButton>
        ),
      }} />
    </Box>

<div className='pt-8 text-left pl-8'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet sem consectetur.</div>

<div className='pt-32'>
<button class="col border border-gray-500 text-sm text-gray-500  py-2 px-16 ml-96 rounded hover:animate-pulse animate duration-1000" onClick={handleCancel}>
  Cancel
</button>
<button class="col bg-[#2A9DEA] hover:bg-blue-700 text-sm text-white  py-2 px-16 ml-4 rounded" >
  Save 
</button>
</div>

        </Box>
      </Container>
    </React.Fragment>
  );
}
